import React, { useContext, useState } from 'react';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import useStyles from 'src/styles/di-theme';
import { Button, Switch, Typography } from '@material-ui/core';
import type { Identifier, XYCoord } from "dnd-core";
import { useRef } from "react";
import { useDrag, useDrop } from "react-dnd";
import { PolicyContext } from './Main';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { ConvertDateAndTimeCell, DirectoryOrIdentityRenderCell, PolicyTagRendererCell } from './Renderer';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useAppToolchain } from 'src/hooks/useAppToolchain';
import useAuth from 'src/hooks/useAuth';
import { Policy } from 'src/types/policyTypes';

const ItemTypes = {
    POLICY: 'policy',
}


interface DragItem {
    index: number;
    id: string;
    type: string;
}


type PolicyRowRenderProps = {
  feed: Policy; 
  index: number;
  moveFeeds: (dragIndex: number, hoverIndex: number) => void;
  id: string; 
  setPolicyFeeds: React.Dispatch<React.SetStateAction<Policy[]>>; 
}


const PolicyRowRender = ({feed, index, moveFeeds, id, setPolicyFeeds}:PolicyRowRenderProps) => {
    const classes = useStyles();
    const {policyChange, prevFeedsResponse, setSelectedPolicyToModify, toggleDrawer, setModifyClicked} = useContext(PolicyContext);
    const [enableTxt, setEnableTxt] = useState(feed.disabled === false? "Enabled" : "Enable");
    const [showDelete, setShowDelete] = useState(false);
    const { api } = useAppToolchain();
    const { auth } = useAuth();
    const ref = useRef<HTMLTableRowElement>(null);


    const [{ handlerId }, drop] = useDrop<
      DragItem,
      void,
      { handlerId: Identifier | null }
    >({
      accept: ItemTypes.POLICY,
      collect(monitor) {
        return {
          handlerId: monitor.getHandlerId()
        };
      },
      hover(item: DragItem, monitor) {
        if (!ref.current) {
          return;
        }
        const dragIndex = item.index;
        const hoverIndex = index;


        // Don't replace items with themselves
        if (dragIndex === hoverIndex) {
          return;
        }


        // Determine rectangle on screen
        const hoverBoundingRect = ref.current?.getBoundingClientRect();


        // Get vertical middle
        const hoverMiddleY =
          (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;


        // Determine mouse position
        const clientOffset = monitor.getClientOffset();


        // Get pixels to the top
        const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;


        // Only perform the move when the mouse has crossed half of the items height
        // When dragging downwards, only move when the cursor is below 50%
        // When dragging upwards, only move when the cursor is above 50%


        // Dragging downwards
        if (dragIndex <= hoverIndex && hoverClientY <= hoverMiddleY) {
          return;
        }


        // Dragging upwards
        if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
          return;
        }


        // Time to actually perform the action
        moveFeeds(dragIndex, hoverIndex);


        // Note: we're mutating the monitor item here!
        // Generally it's better to avoid mutations,
        // but it's good here for the sake of performance
        // to avoid expensive index searches.
        item.index = hoverIndex;


      }
    });


    const [{isDragging}, drag] = useDrag({
      type: ItemTypes.POLICY,
      item: () => {
        return { id, index };
      },
      collect: (monitor: any) => ({
        isDragging: !!monitor.isDragging(),
      })
    });


    if(policyChange) {
      drag(drop(ref));
    }else {
      drag(null);
    }


    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };

    function handleToggleChange(e, feed) {
      if(feed.isDefault) return;
      feed = {...feed, disabled: !e.target.checked}
      setPolicyFeeds(prev => {
        const updatedData = prev.map(prevFeed => {
            if(prevFeed.id === feed.id) {
                return {...feed}; 
            } else {
                return prevFeed;
            }
        });
        return updatedData;
    });
      updateEnableText(e.target.checked);
      api.updatePolicyFeed(
        auth.accountDetails.account.id,
        feed,
        feed.id
      )
    }


    function updateEnableText(feed) {
      feed ? setEnableTxt("Enabled") : setEnableTxt("Enable"); 
    }


    function handleModification(e, feed){
      setAnchorEl(null);
      setShowDelete(false);
      setSelectedPolicyToModify(feed);
      setModifyClicked(true);
      toggleDrawer('right', true)(e);
    }

    function cancelDelete() {
      setShowDelete(false);
    }

    const handleDelete = () => {
      setAnchorEl(null);
      setShowDelete(true);
    };

    function deletePolicy(feed) {
      if(!feed?.id) return;

      api.deletePolicyFeed(
        auth.accountDetails.account.id,
        feed.id
      ).then(res => {
        setPolicyFeeds(feeds => {
          const updatedFeeds = feeds.filter(prevFeed => prevFeed.id != feed.id).map((feed, index) => {
            return {
                ...feed,
                priority: index + 1
            };
        });

        const priorityChange = updatedFeeds.reduce((acc, curr, index) => {
          if(curr.id !== prevFeedsResponse.current[index].id) {
            acc.push({
                id: curr.id,
                priority: index + 1
              }) 
          }
          return acc;
        }, []);

        if(priorityChange.length > 0) {
          api.updatePolicyEngineFeedPriorities(
            auth.accountDetails.account.id,
            priorityChange
          );
        }

        prevFeedsResponse.current = updatedFeeds;
        return updatedFeeds;
        });
      });
      setShowDelete(false);
    }


  return (
    <>
        <TableRow 
              sx={{height: '90px' }}
              style={policyChange ? { cursor: 'pointer' } : { cursor: 'default' }}
              ref={ref}
              data-handler-id={handlerId}
        >
          <TableCell style = {{width: '80px', textAlign: 'left'}} component="th" scope="row">
            <div className={classes['flex_ver_center']}>
              {policyChange && <DragIndicatorIcon color="action" />}
              {policyChange && <Typography 
              style={{
                  borderRadius: '5px',
                  padding: '6px 5px',
                  border: '1px solid #C8C8C7'
                }}>
                  {index + 1}
                </Typography>}
                {!policyChange && <Typography>
                  {index + 1}
                </Typography>}
            </div>
          </TableCell>
          <TableCell style = {{width: '180px', textAlign: 'left'}} align="right">{feed?.name.replace(/ /g, "\u00A0")}</TableCell>
          <TableCell style = {{width: '250px', textAlign: 'left'}} align="right">
            {PolicyTagRendererCell(feed)}
          </TableCell>
          <TableCell style = {{width: '180px', textAlign: 'left'}} align="right">
            {DirectoryOrIdentityRenderCell(feed?.conditions)}
          </TableCell>
          <TableCell style = {{width: '130px', textAlign: 'left'}} align="right">{feed?.conditions?.identity?.authorship.join(", ")}</TableCell>
          <TableCell style = {{width: '130px', textAlign: 'left'}} align="right">{`${feed?.actions}`}</TableCell>
          <TableCell style = {{width: '130px', textAlign: 'left'}} align="right">{feed?.createdBy?.name}</TableCell>
          <TableCell style = {{width: '130px', textAlign: 'left'}} align="right">
            {ConvertDateAndTimeCell(feed?.lastModified)}
          </TableCell>
          <TableCell style = {{width: '150px', textAlign: 'left'}}  >
            <div className={classes['flex_ver_center']} >
              <div className={classes['flex_ver_center']} style={{marginRight: '10px'}}>
                <Switch color='primary' size='small' 
                disabled={feed?.isDefault === true} 
                defaultChecked = {feed?.disabled === false ? true : false} 
                onChange={(e) => handleToggleChange(e, feed)} />
                <Typography
                  variant="body1"
                  color="textPrimary"
                  noWrap
                >
                  {enableTxt}
                </Typography>
              </div>
              {!policyChange && <div>
                  <IconButton
                    aria-label="more"
                    id="long-button"
                    aria-controls={open ? 'long-menu' : undefined}
                    aria-expanded={open ? 'true' : undefined}
                    aria-haspopup="true"
                    onClick={handleClick}
                  >
                    <MoreVertIcon />
                  </IconButton>
                    <Menu 
                      id="long-menu"
                      anchorEl={anchorEl}
                      open={open}
                      onClose={handleClose}
                      anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                      }}
                      transformOrigin={feed?.isDefault ? {vertical: 45, horizontal: 45 }: {vertical: 80, horizontal: 65 }}
                    >
                      <MenuItem onClick={(e) => handleModification(e, feed)}>
                        Edit
                      </MenuItem>
                      {!feed?.isDefault && <MenuItem onClick={() => handleDelete()}>
                        Delete
                      </MenuItem>}
                    </Menu> 
                </div>
              }
            </div>  
            {showDelete && <div className={classes['font-small-0.75']} style={{textAlign: 'center'}}>
              <div >Delete Policy ?</div>
              <div>
                <Button style={{padding: '0px', fontSize: '0.75rem', minWidth: '35px'}} onClick={cancelDelete}>No</Button>
                /
                <Button style={{padding: '0px', fontSize: '0.75rem', minWidth: '35px'}} onClick={() => deletePolicy(feed)}>Yes</Button>
              </div>
            </div>}
          </TableCell>
        </TableRow>
    </>
  )
}


export default PolicyRowRender;