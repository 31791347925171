import React from 'react';

import { TableCell, Box } from '@material-ui/core';
import { InsertDriveFile as FileIcon } from '@material-ui/icons';
import useStyles from 'src/styles/di-theme';
import azurefiles from '../../static/azurefiles.png';
import onedrive from '../../static/onedrive.png';
import sharepoint from '../../static/sharepoint.png';

export function CloudTypeCell({
	cloudType,
}: {
	cloudType: string;
}): React.ReactElement {
	return (
		<TableCell align="left">
			<CaseCloudTypeInfo cloudType={cloudType} />
		</TableCell>
	);
}

export function CaseCloudTypeInfo({
	cloudType,
}: {
	cloudType: string;
}): React.ReactElement {
	let typeIcon;
	const classes = useStyles();
	let text = '';
	const imgClass = { position : 'relative', top : 4}
	switch (cloudType.toLowerCase()) {
		case 'onedriveconnector':
		case 'onedrive':
			typeIcon = (
				<img src={onedrive} alt="onedrive" style={imgClass} width="20" height="20" />
			);
			text = 'OneDrive';
			break;
		case 'sharepointconnector':
		case 'sharepoint':
		case 'sharepointlibraryconnector':
			typeIcon = (
				<img src={sharepoint} alt="sharepoint"  style={imgClass}  width="20" height="20" />
			);
			text = 'Sharepoint';
			break;
			
		case 'azurefilesconnector':
			typeIcon = (
				<img src={azurefiles} alt="azurefiles"  style={imgClass} width="20" height="20" />
			);
			text = 'Azure';
			break;
		default:
			typeIcon = <FileIcon />;
	}
	return (
		<div className={classes['d_inline']}>
		
			{typeIcon} {text}
		</div>
	);
}
