import * as React from 'react';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { Chip, debounce } from '@mui/material';
import { useAppToolchain } from 'src/hooks/useAppToolchain';
import useAuth from 'src/hooks/useAuth';

type groupData = {
  id: string;
  name: string | null;
  email?: string | null;
  type?: string | null;
}
type userData = {
  id: string;
  name: string | null;
  email?: string | null;
  remoteId?: string | null;
  barracudaId?: string | null;
}
type SelectedGroups = {
  current: groupData[];
};

type SelectedUsers = {
  current: userData[];
};

type IdentityProps = {
  selectedUsers: SelectedUsers;
  setError: (error: boolean) => void;
  selectedGroups: SelectedGroups;
}

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

function IdentityGroups({selectedGroups, setError, selectedUsers}: IdentityProps) {

  const [data, setData] = React.useState([]);
  const [selectedTags, setSelectedTags] = React.useState(() => selectedGroups.current[0]?.id === 'All' ? [] : selectedGroups.current.map(group => ({ id: group.id, name: group.name })));
  const clearedAllgroups = React.useRef(false);

  const prevGroupsData = React.useRef([]);
  const [isEmptyRes, setIsEmptyRes] = React.useState(false);
  const [inputValue, setInputValue] = React.useState('');

  const { api } = useAppToolchain();
  const { auth } = useAuth();
  const allGroups = [{
    "id": "All",
    "name": null,
    "email": null
  }];

  const handleAllGroupChange = (event) => {
    const { checked } = event.target;
    setSelectedTags(checked ? data.map(group => ({ id: group.id, name: group.name })) : []);
    selectedGroups.current = checked ? allGroups : [];
  };

  const handleAllGroupLabelClick = (event) => {
    if(!event.target?.firstChild) return;

    const { checked } = event.target.children[0].firstChild;
    setSelectedTags(!checked ? data.map(group => ({ id: group.id, name: group.name })) : []);
    selectedGroups.current = !checked ? allGroups : [];
  }

  const handleAllDelete = () => {
    setSelectedTags([]);
    selectedGroups.current = [];
  }

  React.useEffect(() => {
    performSearch('');

    return () => {
      prevGroupsData.current = [];
      setData([]);
    }
  }, []);

  function convertData(data) {
    return data.reduce((acc, curr) => {
        if(!acc[curr.id] && curr.id !== 'All') {
            acc[curr.id] = curr;
        }
        return acc;
    }, {})
  }

  const performSearch = React.useMemo(
    () => debounce((query) => {
        api.getPolicyGroups(
          auth.accountDetails.account.id,
          query
        ).then(res => {
          if(res) {
            if(res.data.length === 0) {
              setIsEmptyRes(true);
            }else { 
              setIsEmptyRes(false);
            }
            
            const hash = convertData([...res.data, ...selectedGroups.current]);
            setData(Object.values(hash));

            if(prevGroupsData.current.length === 0) {
              prevGroupsData.current = res.data;
            }

            if(selectedGroups.current[0]?.id === 'All') {
              setSelectedTags(() => res.data.map(group => ({ id: group.id, name: group.name })));
              selectedGroups.current = allGroups;
            }
          }
        })
    }, 500),
    [api, auth.accountDetails.account.id]
  );


  const handleInputChange = (e, value) => {
    if(selectedGroups.current[0]?.id === 'All') {
      return;
    }
    const query = value.trimStart();
    setInputValue(query);
    performSearch(query);
  }

  const handleInputKeyDown = (event) => {
    if (event.keyCode === 8 && selectedTags.length === prevGroupsData.current.length) {
      clearedAllgroups.current = true;
    }
  };

  const handleBlur = () => {
    if(inputValue.length > 0) {
      performSearch('');
    }
    setInputValue('');

    if(selectedTags.length === 0 && selectedUsers.current.length === 0) {
      setError(true);
    }else {
      setError(false);
    }
  }

  const checkOptionSelected = (option) => {
    const obj = selectedTags.find(group => group.id === option.id);
    return obj?.id === option.id;
  }

  const filterOptions = (options) => {
    return options;
  };

  return (
    <>
      <Autocomplete
        multiple
        id="checkboxes-tags-demo"
        options={[{id: -1, name: 'All Groups', email: '', type: ''}, ...data]}
        limitTags={1}
        getLimitTagsText={(tags) => <span style={{fontSize: '12px', color: 'gray', fontWeight: 500}}>{`+${tags} more`}</span>}
        disableCloseOnSelect
        clearOnBlur={false}
        value={selectedTags.map(group => data.find(option => option.id === group.id))}
        getOptionLabel={(option) => option?.name || ''}
        inputValue={inputValue}
        filterOptions={filterOptions}
        onInputChange={handleInputChange}
        onChange={(_, newValue) => {
          setInputValue(inputValue);
          if(clearedAllgroups.current) {
            setSelectedTags([]);
            selectedGroups.current = [];
            clearedAllgroups.current = false;
          }else {
            setSelectedTags(newValue.filter(ele => ele.name !== "All Groups").map(group => ({ id: group.id, name: group.name })));
            selectedGroups.current = newValue.filter(ele => ele.name !== "All Groups");
            if(selectedGroups.current.length === prevGroupsData.current.length) {
              selectedGroups.current = allGroups;
            }
          }
        }}

        renderOption={(props, option) => {
          return (
            <div key={option.id}>
              {option?.name === "All Groups" && inputValue.length === 0 && !isEmptyRes &&
                  <li {...props} onClick={handleAllGroupLabelClick}>
                      <Checkbox
                        id='all'
                        icon={icon}
                        checkedIcon={checkedIcon}
                        checked={selectedTags.length === prevGroupsData.current.length && prevGroupsData.current.length !== 0 && !isEmptyRes}
                        onChange={handleAllGroupChange}
                        sx={{
                          color: 'black',
                          '&.Mui-checked': {
                            color: 'black',
                          },
                        }}
                      />
                      All Groups
                  </li>}

                  {option?.name !== "All Groups" && <li style={{ marginLeft: '12px' }} {...props}>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8 }}
                      checked={checkOptionSelected(option)}
                      sx={{
                        color: 'black',
                        '&.Mui-checked': {
                          color: 'black',
                        },
                      }}
                    />
                    {option?.name}
                  </li>}
            </div>
          )
        }}
        size='small'
        renderInput={(params) => {
          return (
              <TextField {...params} 
                label="Groups" 
                onKeyDown={handleInputKeyDown}
                InputLabelProps={{
                  style: { fontSize: '10px', fontWeight: 'bold', lineHeight: 2 }
                }}
                onBlur={handleBlur}
              />
          )
          }}
        renderTags={(values, getTagProps) => {
          if(values[0] == null) return [];
          const renderedTags = [];
            if (values.length === prevGroupsData.current.length && prevGroupsData.current.length !== 0 && !isEmptyRes) {
              renderedTags.push(
                <Chip 
                sx={{
                  fontSize: '10px',
                  height: '20px',
                  fontWeight: 500
                }}
                size="small"
                key="all-groups" label="All Groups"
                onDelete={handleAllDelete} />
              );
            } else {
              values.forEach((option, index) => {
                renderedTags.push(
                  <Chip 
                  sx={{
                    fontSize: '10px',
                    height: '20px',
                    fontWeight: 500
                  }}
                  size="small"
                  key={option?.id} label={option?.name} {...getTagProps({ index })} />
                );
              });
            }
          return renderedTags;
        }}       
      />
    </>
  );
}

export default IdentityGroups;