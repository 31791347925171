import { TableCell } from '@material-ui/core';
import React from 'react';
import { CasePermissionStats } from 'src/types/dataTypes';

export function SharingCell({
	dataItem: { permissionStats },
}: {
	dataItem: {
		permissionStats: CasePermissionStats;
	};
}): React.ReactElement {
	return (
		<TableCell align="left">
			<SharingInfo permissionStats={permissionStats} />
		</TableCell>
	);
}

export function SharingInfo({
	permissionStats,
}: {
	permissionStats: CasePermissionStats;
}): React.ReactElement {
	if (permissionStats && permissionStats.publicRoles) {
		return <div style={{ textAlign: 'center' }}>Public Link</div>;
	} else if (
		permissionStats &&
		Object.keys(permissionStats.externalRoleCounts).length
	) {
		return (
			<div style={{ textAlign: 'center' }}>Password Protected Link</div>
		);
	} else if (
		permissionStats &&
		Object.keys(permissionStats.userRoleCounts).length &&
		(Object.prototype.hasOwnProperty.call(
			permissionStats.userRoleCounts,
			'READ',
		) ||
			Object.prototype.hasOwnProperty.call(
				permissionStats.userRoleCounts,
				'WRITE',
			))
	) {
		return <div style={{ textAlign: 'center' }}>Internal</div>;
	}

	return null;
}
