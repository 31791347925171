const PARENT_FEATURES = {
	CASEVIEW: 'case view',
	FEEDS: 'feeds',
	LOGIN: 'login',
	GLOBAL: 'global',
	NAV: 'navigation',
	SETTINGS: 'settings',
	ONBOARDING_WIZARD: 'onboarding',
};

export interface FeatureAction {
	id: string;
	feature: string;
}

const FEATURES = {
	pageChange: {
		id: 'URL Changed',
		feature: PARENT_FEATURES.GLOBAL,
	},
	feedsConfidenceCheck: {
		id: 'Tag Confidence Click',
		feature: PARENT_FEATURES.FEEDS,
	},
	feedsLoadMoreButtonClick: {
		id: 'Load More Button Click',
		feature: PARENT_FEATURES.FEEDS,
	},
	feedsDismissFeedButtonClick: {
		id: 'Dismiss Feed Button Click',
		feature: PARENT_FEATURES.FEEDS,
	},
	feedsPreviewFeedButtonClick: {
		id: 'Preview Feed Button Click',
		feature: PARENT_FEATURES.FEEDS,
	},
	feedsReviewClick: {
		id: 'Feed Review Click',
		feature: PARENT_FEATURES.FEEDS,
	},
	feedsFilterChanged: {
		id: 'Feed Classifier Changed',
		feature: PARENT_FEATURES.FEEDS,
	},
	feedsFiltered: {
		id: 'Feeds Filtered',
		feature: PARENT_FEATURES.FEEDS,
	},
	loginButtonClick: {
		id: 'Login Button Click',
		feature: PARENT_FEATURES.LOGIN,
	},
	loginTermsAccepted: {
		id: 'Terms Accepted',
		feature: PARENT_FEATURES.LOGIN,
	},
	settingsConnectSharepointButtonClick: {
		id: 'Connect Sharepoint Button Clicked',
		feature: PARENT_FEATURES.SETTINGS,
	},
	settingsClassifierDeleteFirstClick: {
		id: 'Delete Classifier First Click',
		feature: PARENT_FEATURES.SETTINGS,
	},
	settingsClassifierDeleteSecondClick: {
		id: 'Delete Classifier',
		feature: PARENT_FEATURES.SETTINGS,
	},
	settingsClassifierEditButtonClick: {
		id: 'Edit Classifier Button Click',
		feature: PARENT_FEATURES.SETTINGS,
	},
	settingsClassifierAddClassifierClick: {
		id: 'Add Classifier Click',
		feature: PARENT_FEATURES.SETTINGS,
	},
	settingsUsersSearch: {
		id: 'User Search',
		feature: PARENT_FEATURES.SETTINGS,
	},
	navDashboardClick: {
		id: 'NavHeader Feeds Click',
		feature: PARENT_FEATURES.LOGIN,
	},
	navSettingsClick: {
		id: 'NavHeader Settings Click',
		feature: PARENT_FEATURES.LOGIN,
	},
	navSettingsSidebarClick: {
		id: 'Settings Sidebar Click',
		feature: PARENT_FEATURES.SETTINGS,
	},

	// ONBOARDING
	onboardingCompleted: {
		id: 'Onboarding Completed',
		feature: PARENT_FEATURES.ONBOARDING_WIZARD,
	},
	onboardingDataLocationChange: {
		id: 'Onboarding Data Location Changed',
		feature: PARENT_FEATURES.ONBOARDING_WIZARD,
	},
	onboardingPageChange: {
		id: 'Onboarding Page Change',
		feature: PARENT_FEATURES.ONBOARDING_WIZARD,
	},
};

export default FEATURES;
