import { Button, Chip, Typography } from '@barracuda-internal/bds-core';
import React, { ChangeEvent } from 'react';
import Grid from '@material-ui/core/Grid';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import useStyles from 'src/styles/di-theme';
import classNames from 'classnames';
type ConnectorType =
	| 'OneDriveConnector'
	| 'OneDriveDiscoveryConnector'
	| 'SharepointLibraryCollectionConnector';

export type ConnectorStatus =
	| 'AUTHORIZED'
	| 'UNAUTHORIZED'
	| 'NOT CONNECTED'
	| 'UNKNOWN';

export interface ConnectorCardProps {
	error?: string;
	isLoading?: boolean;
	isDisabled?: boolean;
	logoSrc: string;
	message?: string;
	onSubmitButtonClick?: (
		event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
	) => void;
	onDisableClick?: (
		event: ChangeEvent<HTMLInputElement>,
		checked: boolean,
	) => void;
	status?: ConnectorStatus;
	title: string;
	subtitle?: string;
	canModify?: boolean;
	render?: string;
}

export function ConnectorCard({
	error,
	isLoading = false,
	onDisableClick,
	onSubmitButtonClick,
	subtitle,
	isDisabled = false,
	status = 'UNKNOWN',
	canModify = false,
	render,
	title,
	logoSrc,
}: ConnectorCardProps) {
	let buttonText: string;
	let banner: string;
	let bannerColor = '';
	if (isLoading) {
		buttonText = 'Checking';
	} else {
		switch (status) {
			case 'AUTHORIZED':
				buttonText = 'Reauthorize';
				if (isDisabled) {
					banner = 'Paused';
					bannerColor = '#FFE8DA';
				} else {
					banner = 'Connected';
					bannerColor = '#DBF6CA';
				}

				break;
			case 'UNAUTHORIZED':
				buttonText = 'Authorize';
				banner = 'Unauthorized!';
				break;
			case 'NOT CONNECTED':
				buttonText = 'Connect';
				banner = 'Not connected';
				break;
			default:
				buttonText = '';
		}
	}

	if (error) {
		banner = error;
	}

	const classes = useStyles();
	let gridClassName = {};
	gridClassName[classes['bg_gray']] = buttonText == 'Connect';
	return (
		<div>
			{render == 'welcome' ? (
				<Grid
					container
					alignItems="center"
					className={classNames(
						classes['border_top_gray'],
						classes['border_bottom_gray'],
						classes['ptb_3'],
						classes['plr_2'],
						classes['mtb_2'],
						gridClassName,
					)}
				>
					<Grid item xs={5}>
						<Grid
							container
							direction="row"
							spacing={1}
							alignItems="center"
						>
							<Grid item>
								<img
									className={classes['icon_4']}
									src={logoSrc}
								></img>
							</Grid>
							<Grid item>
								<Typography variant="h3" color="textPrimary">
									{title}
								</Typography>
							</Grid>
						</Grid>
						<Typography variant="body1" color="textSecondary">
							{subtitle}
						</Typography>
					</Grid>
					<Grid item xs={3}>
						{!isLoading  ? (
							<Chip
								label={banner}
								size="small"
								style={{
									backgroundColor: bannerColor,
									fontStyle: 'bold',
									margin: '2px',
								}}
							></Chip>
						) : null}
					</Grid>
					<Grid item xs={4}>
						<Button
							disabled={isLoading}
							isLoading={isLoading}
							onClick={onSubmitButtonClick}
							variant="outlined"
							size="medium"
							color="primary"
						>
							{buttonText}
						</Button>
					</Grid>
				</Grid>
			) : (
				<Grid
					container
					alignItems="center"
					className={classNames(
						classes['border_top_gray'],
						classes['ptb_2'],
						classes['plr_4'],
						gridClassName,
					)}
				>
					<Grid item xs={2}>
						{!isLoading && buttonText != 'Connect' ? (
							<FormControlLabel
								control={
									<Switch
										name="checkedB"
										color="primary"
										onChange={onDisableClick}
										checked={!isDisabled}
									/>
								}
								label=""
							/>
						) : null}
					</Grid>
					<Grid item xs={3}>
						<Typography
							variant="h3"
							color="textPrimary"
							className={classNames(classes['d_inline'])}
						>
							{subtitle}
						</Typography>
					</Grid>
					<Grid item xs={3}>
						<div>
							{!isLoading ? (
								<Chip
									label={banner}
									size="small"
									style={{
										backgroundColor: bannerColor,
										fontStyle: 'bold',
										margin: '2px',
									}}
								></Chip>
							) : null}
						</div>
					</Grid>
					<Grid item xs={2}>
						<Button
							disabled={isLoading}
							isLoading={isLoading}
							onClick={onSubmitButtonClick}
							variant="outlined"
							size="medium"
							color="primary"
						>
							{buttonText}
						</Button>
					</Grid>
				</Grid>
			)}
		</div>
	);
}
