import { Grid } from '@material-ui/core';
import React from 'react';
import { ConnectorServiceCard } from 'src/components/connectors';
import OneDriveConnectorCard from 'src/components/connectors/OneDriveConnectorCard';
import SharepointConnectorCard from 'src/components/connectors/SharepointConnectorCard';
import { Typography } from '@barracuda-internal/bds-core';
import useStyles from 'src/styles/di-theme';
import { useApiRequest } from 'src/hooks/useApi';
import { useAppToolchain } from 'src/hooks/useAppToolchain';
import useAuth from 'src/hooks/useAuth';
interface ServiceGridProps {
	connectcount?;
}

/**
 * This defines all the connectors that DI can connect to.
 * It is used, for instance, to determine what connectors to
 * show the user as options to connect to.
 */
export const CONNECTOR_MAP: {
	name: string;
	connectorTypes: string[];
	personal?: string;
	company?: string;
	card: React.JSXElementConstructor<ConnectorServiceCard>;
}[] = [
	{
		name: 'OneDrive',
		connectorTypes: ['OneDriveConnector', 'OneDriveDiscoveryConnector'],
		personal: 'OneDriveConnector',
		company: 'OneDriveDiscoveryConnector',
		card: OneDriveConnectorCard,
	},
	{
		name: 'SharePoint',
		connectorTypes: ['SharepointLibraryCollectionConnector'],
		company: 'SharepointLibraryCollectionConnector',
		card: SharepointConnectorCard,
	},
];

export function ServiceSelectionGrid({
	connectcount,
}: ServiceGridProps): React.ReactElement {
	const classes = useStyles();
	/**
	 * Once we have the subscription and all the connectors,
	 * we can filter them down to just the user's connectors.
	 * There are a few checks we do here to check what options to show,
	 * because there are some services which it doesn't make sense
	 * to show if they are already connected in a different usage context.
	 */
	let allConnectorStatus = {};
	let updateConnectCount = (connector) => {
		allConnectorStatus[connector] = true;
		connectcount(Object.entries(allConnectorStatus).length);
	};
	const { api } = useAppToolchain();
	const { auth } = useAuth();
	// Get the user subscription, which we need to get their connectors
	const [subStatus, subscriptions, subscriptionError] = useApiRequest(
		(signal) => api.getUserSubscriptions(auth.user.id, { signal }),
		[auth.user.id],
	);
	const lastActiveAccount = localStorage.getItem('lastActiveAccount');
	let subscription = null;
	if( !lastActiveAccount || lastActiveAccount == null ){
		//Pick first one
		if( subscriptions )  subscription = subscriptions[0];
	}else{
		subscriptions && subscriptions.forEach( sub => {
			if( sub.accountId == lastActiveAccount ){
				subscription = sub;
			}
		})
	}
	return (
		<div className={classes['mt_3']}>
			<Grid container spacing={8} alignItems="flex-start">
				<Grid item xs={6} >
					<Typography variant="h2" color="textPrimary" gutterBottom>
						My Account
					</Typography>
					<Typography
						variant="body1"
						color="textPrimary"
						gutterBottom
					>
						Try out Data Inspector by scanning just your Personal OneDrive for Business account
					</Typography>
					<OneDriveConnectorCard
						usage="personal"
						render="welcome"
						subtitle="OneDrive for Business"
						subscription={subscription}
						onStatus={(connected) => {
							if (connected)
								updateConnectCount('one-drive-connector');
						}}
					></OneDriveConnectorCard>
				</Grid>
				<Grid item xs={6} alignItems="flex-start">
					<Typography variant="h2" color="textPrimary" gutterBottom>
						Entire Tenant
					</Typography>
					<Typography
						variant="body1"
						color="textPrimary"
						gutterBottom
					>
						Log in with your Office 365 Global Admin user account to scan your entire tenant.
					</Typography>
					<OneDriveConnectorCard
						onStatus={(connected) => {
							if (connected)
								updateConnectCount('company_one_drive');
						}}
						subscription={subscription}
						usage="company"
						render="welcome"
						subtitle="Office 365 Tenant"
					></OneDriveConnectorCard>
					<SharepointConnectorCard
						onStatus={(connected) => {
							if (connected)
								updateConnectCount('company_share_point');
						}}
						subscription={subscription}
						usage="company"
						render="welcome"
						subtitle="Office 365 Tenant"
					></SharepointConnectorCard>
				</Grid>
			</Grid>
		</div>
	);
}
