import { Button, Typography } from '@barracuda-internal/bds-core';
import React, { ReactElement, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import useStyles from 'src/styles/di-theme';
interface Props {}

function CookieSetting({}: Props): ReactElement {
	const handleCookieSettingClick = () => {
		document.getElementById('ot-sdk-btn').click();
	};

	const classes = useStyles();

	return (
		<div>
			<Typography
				variant="h3"
				color="textPrimary"
				className={classes['mb_4']}
			>
				Cookie Options
			</Typography>

			<div className={classes['ml_2']}>
				<Typography
					variant="h4"
					color="textPrimary"
					className={classes['mb_2']}
				>
					Cookie policy confirmation
				</Typography>
				<Grid container spacing={1}>
					<Grid item xs={3}>
						<Button
							variant="outlined"
							onClick={() => handleCookieSettingClick()}
							color="secondary"
						>
							Open Settings
						</Button>
					</Grid>
					<Grid item xs={9}>
						<Typography
							variant="body1"
							color="textPrimary"
							className={classes['mb_2']}
						>
							Barracuda Sites may request cookies to be set on
							your device. Open Cookie Settings to find out more,
							adjust your setings if needed and confirm your
							choice.
						</Typography>
					</Grid>
				</Grid>
			</div>
		</div>
	);
}

export default CookieSetting;
