import { Step, StepLabel, Stepper } from '@barracuda-internal/bds-core';
import { Container, Divider } from '@material-ui/core';
import React, { ReactElement } from 'react';
import FEATURES from 'src/featureMap';
import withAnalytics from 'src/lib/withAnalytics';
import ServicesSetup from 'src/routes/onboarding/ServicesSetup';
import { OnboardingStepProps } from 'src/routes/onboarding/types';
import Welcome from 'src/routes/onboarding/Welcome';
import useStyles from 'src/styles/di-theme';
import RequestPIConsent from 'src/routes/onboarding/RequestPIConsent';
import DataSetup from './onboarding/DataSetup';

interface Props {}

type StepsType = {
	label: string;
	component: (props: OnboardingStepProps) => ReactElement;
	width: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false;
};

const STEPS: StepsType[] = [
	{
		label: 'Welcome',
		component: Welcome,
		width: 'sm',
	},
	{
		label: 'Personal Information Consent',
		component: RequestPIConsent,
		width: 'sm',
	},
	{
		label: 'Select data storage location',
		component: DataSetup,
		width: 'sm',
	},
	{
		label: 'Connect Services',
		component: ServicesSetup,
		width: 'lg',
	},
];

function OnboardingPage({}: Props): ReactElement {
	const classes = useStyles();
	const isSetup = localStorage.getItem('is_setup') == 'true';
	const [activeStep, _setActiveStep] = React.useState(isSetup ? 3 : 0);

	const setActiveStep = withAnalytics(
		FEATURES.onboardingPageChange,
		(step) => {
			_setActiveStep(step);
		},
	);

	const PageComponent = STEPS[activeStep].component;
	return (
		<div>
			<div className={[classes['w_720'], classes['m_auto']].join(' ')}>
				<Stepper activeStep={activeStep}>
					{STEPS.map((page) => (
						<Step key={page.label}>
							<StepLabel>{page.label}</StepLabel>
						</Step>
					))}
				</Stepper>
			</div>
			<Divider></Divider>

			<Container maxWidth={STEPS[activeStep].width}>
				<PageComponent
					setStep={setActiveStep}
					activeStep={activeStep}
				/>
			</Container>
		</div>
	);
}

export default OnboardingPage;
