import { Grid } from '@material-ui/core';
import React, { ReactElement } from 'react';
import OneDriveConnectorCard from 'src/components/connectors/OneDriveConnectorCard';
import SharepointConnectorCard from 'src/components/connectors/SharepointConnectorCard';
import { useApiRequest } from 'src/hooks/useApi';
import { useAppToolchain } from 'src/hooks/useAppToolchain';
import useAuth from 'src/hooks/useAuth';
import LoadingIndicator from 'src/lib/components/LoadingIndicator';
import { DIConnector } from 'src/types/dataTypes';
import onedrive from '../../static/onedrive.png';
import sharepoint from '../../static/sharepoint.png';
import useStyles from 'src/styles/di-theme';
import { Typography } from '@barracuda-internal/bds-core';
import classNames from 'classnames';

interface Props {}

export const CONNECTOR_MAP = {
	sharepoint: {
		type: 'SharepointLibraryCollectionConnector',
	},
};

function ConnectorSettings({}: Props): ReactElement {
	const { api } = useAppToolchain();
	const { auth } = useAuth();
	const classes = useStyles();

	const [state, connectors, err] = useApiRequest<DIConnector[]>(
		() => api.getAccountConnectors(auth.accountDetails.account.id, ''),
		[auth.accountDetails.account.id],
	);

	const isAdmin = auth?.isFullAdmin();

	// Get the user subscription, which we need to get their connectors
	const [subStatus, subscriptions, subscriptionError] = useApiRequest(
		(signal) => api.getUserSubscriptions(auth.user.id, { signal }),
		[auth.user.id],
	);
	const lastActiveAccount = localStorage.getItem('lastActiveAccount');
	let subscription = null;
	if( !lastActiveAccount || lastActiveAccount == null ){
		//Pick first one
		if( subscriptions )  subscription = subscriptions[0];
	}else{
		subscriptions && subscriptions.forEach( sub => {
			if( sub.accountId == lastActiveAccount ){
				subscription = sub;
			}
		})
	}
	

	return (
		<div
			className={classNames(
				classes['pt_4'],
				classes['m_auto'],
				classes['w_max_720'],
			)}
		>
			<Typography variant="h1" color="textPrimary">
				Connectors
			</Typography>
			{isAdmin && connectors ? (
				<div>
					<div
						className={classNames(
							classes['mt_6'],
							classes['border_bottom_gray'],
						)}
					>
						<Grid
							container
							direction="row"
							alignItems="center"
							spacing={1}
							className={classNames(classes['mb_2'])}
						>
							<Grid item>
								<img
									className={classes['icon_4']}
									src={onedrive}
								></img>
							</Grid>
							<Grid item>
								<Typography variant="h2" color="textPrimary">
									One Drive
								</Typography>
							</Grid>
						</Grid>
						<OneDriveConnectorCard subscription={subscription} usage="personal"></OneDriveConnectorCard>
						<OneDriveConnectorCard  subscription={subscription} usage="company"></OneDriveConnectorCard>
					</div>
					<div
						className={classNames(
							classes['mt_6'],
							classes['border_bottom_gray'],
						)}
					>
						<Grid
							container
							direction="row"
							alignItems="center"
							spacing={1}
							className={classNames(classes['mb_2'])}
						>
							<Grid item>
								<img
									className={classes['icon_4']}
									src={sharepoint}
								></img>
							</Grid>
							<Grid item>
								<Typography variant="h2" color="textPrimary">
									Share Point
								</Typography>
							</Grid>
						</Grid>
						<SharepointConnectorCard  subscription={subscription}  usage="company"></SharepointConnectorCard>
					</div>
				</div>
			) : (
				<LoadingIndicator />
			)}
		</div>
	);
}

export default ConnectorSettings;
