import React, { useState } from 'react';
import {
	DataTable,
	DataTableColumn,
	Typography,
	IconButton,
} from '@barracuda-internal/bds-core';

import { GridNoRecords} from '@progress/kendo-react-grid';
import LoadingIndicator from 'src/lib/components/LoadingIndicator';
import { TableCell } from '@material-ui/core';
import {  UserRendererCell, DecisionRenderer, PolicyandActionsRendererCell } from './Renderer';
import noSearch from 'src/static/no_search_violations.svg';
import TablePagination from 'src/components/TablePagination';
import moment from 'moment';
import useStyles from 'src/styles/di-theme';
import { CaseCloudTypeInfo } from 'src/components/cases/CloudTypeCell';
import noViolations from 'src/static/no_new_violations.svg';
import { TableChart } from '@material-ui/icons';
import ColumnSelector from 'src/components/ColumnSelector';
import Cookies from 'js-cookie';
import { DefaultColumnHeader } from 'src/components/Renderer';
import TooltipColumn from 'src/components/TooltipColumn';
import classNames from 'classnames'; 
export default function GridPanel({
	feeds,
	loading,
	pageSize,
	page,
	total,
	onPageChange,
	sort,
	sortDir,
	onSortChange,
	isPopup=false,
	width,
	searchText,
	selectedFilters
}) {
	const classes = useStyles();
	let height = 'calc(100vh - 240px)';
	if (isPopup) {
		width = '1024px';
		height = '600px';
	}
	const columnCookieKey = 'scanlogs_columns_v2';
	const [openColumnSelector, setOpenColumnSelector] = React.useState(false);

	const allColumns = [
		{
			value: 'Decision',
			disable: true,
		},
		{
			value: 'File',
			disable: true,
		},
		{
			value: 'Scanned',
			disable: true,
		},
		{
			value: 'Owner/Creator',
			disable: false,
		},
		{
			value: 'Status',
			disable: false,
		},
		{
			value: 'Policies Invoked',
			disable: false,
		},
		{
			value: 'Action Taken',
			disable: false,
		}
	];
	const getDefaultColumns = () => {
		let columns = [
			'Decision',
			'File',
			'Scanned',
			'Owner/Creator',
			'Status',
			'Policies Invoked',
			'Action Taken'
		];
		if (Cookies.get(columnCookieKey) != null) {
			columns = JSON.parse(Cookies.get(columnCookieKey));
		}
		return columns;
	};
	const [visibleColumns, setVisibleColumns] = React.useState(
		getDefaultColumns(),
	);

	return (
		<div className={classes['di_kendo_grid']}>
			{!isPopup ? (
				<IconButton
					className={classes['setting_button_right']}
					onClick={() => {
						setOpenColumnSelector(true);
					}}
				>
					<TableChart />
				</IconButton>
			) : null}

			{openColumnSelector ? (
				<div className={classes['setting_window']}>
					<ColumnSelector
						allColumns={allColumns}
						selectedColumns={visibleColumns}
						onClose={() => {
							setOpenColumnSelector(false);
						}}
						onSelectionChange={(selection) => {
							Cookies.set(
								columnCookieKey,
								JSON.stringify(selection),
							);
							setVisibleColumns(selection);
						}}
					></ColumnSelector>
				</div>
			) : null}

			<div
				style={{
					maxHeight: height,
					width: width,
					minWidth: width,
					overflow: 'auto',
				}}
			>
				{loading ? (
					<div
						style={{
							height: 200,
						}}
					>
						<LoadingIndicator message="Loading ..." />
					</div>
				) : (
					<DataTable
						data={feeds}
						onSortChange={(e) => {
							//console.log(e);
							if (e.sort.length > 0) {
								onSortChange(e.sort[0].field, e.sort[0].dir);
							} else {
								onSortChange('', '');
							}
						}}
						sort={[{ field: sort, dir: sortDir }]}
						sortable={true}
						style={{
							border: 0,
						}}
					>
						<GridNoRecords>&nbsp;</GridNoRecords>

						<DataTableColumn
							cell={DecisionRenderer}
							field="decision"
							title="Decision"
							width="130px"
							sortable={false}
							headerCell={DefaultColumnHeader}
						/>

						<DataTableColumn
							cell={({ dataItem, className }) => {
								return (
									<TableCell className={className}>
										<Typography
											variant="body1"
											color="textPrimary"
											noWrap
										>
											{dataItem.fileName}
										</Typography>
										<TooltipColumn
											content={
												<Typography
													variant="body1"
													color="textSecondary"
													noWrap
												>
													<CaseCloudTypeInfo
														cloudType={
															dataItem.platform
														}
													></CaseCloudTypeInfo>{' '}
													{' > '}{' '}
													{dataItem.filePath.join(
														' > ',
													)}
												</Typography>
											}
										></TooltipColumn>
									</TableCell>
								);
							}}
							field="file"
							title="File"
							width={350}
						/>

						{visibleColumns.indexOf('Scanned') != -1 ? (
							<DataTableColumn
								cell={({ dataItem }) => (
									<TableCell>
										<Typography
											variant="body1"
											color="textPrimary"
											noWrap
											component="span"
										>
											{moment(dataItem.scanDate).format(
												'MMM, DD',
											)}
										</Typography>
										<Typography
											variant="body1"
											color="textSecondary"
											className={classes['pl_1']}
											noWrap
											component="span"
										>
											{moment(dataItem.scanDate).format(
												' LT',
											)}
										</Typography>
									</TableCell>
								)}
								field="scanDate"
								title="Scanned"
								width="160px"
							/>
						) : null}
						{visibleColumns.indexOf('Owner/Creator') != -1 ? (
							<DataTableColumn
								cell={UserRendererCell}
								field="owner"
								title="Owner/Creator"
								width="200px"
								sortable={false}
								headerCell={DefaultColumnHeader}
							/>
						) : null}
						{visibleColumns.indexOf('Status') != -1 ? (
							<DataTableColumn
								cell={({ dataItem }) => (
									<TableCell>
										<Typography
											variant="body1"
											color="textPrimary"
											noWrap
										>
											{dataItem.state}
										</Typography>
									</TableCell>
								)}
								field="state"
								title="Status"
								sortable={false}
								headerCell={DefaultColumnHeader}
								width="130px"
							/>
						) : null}
						{visibleColumns.indexOf('Policies Invoked') != -1 ? (
							<DataTableColumn
								cell={(dataItem) => <PolicyandActionsRendererCell dataItem={dataItem} type='policies' />}
								field="policy"
								title="Policies Invoked"
								width="200px"
								sortable={false}
								headerCell={DefaultColumnHeader}
							/>
						) : null}
						{visibleColumns.indexOf('Action Taken') != -1 ? (
							<DataTableColumn
								cell={(dataItem) =>  <PolicyandActionsRendererCell dataItem={dataItem} type='actions' />}
								field="action"
								title="Action Taken"
								width="200px"
								sortable={false}
								headerCell={DefaultColumnHeader}
							/>
						) : null}
					</DataTable>
				)}
			</div>
			{total == 0 && !loading ? (
				<>
				<div
						style={{
							position: 'fixed',
							zIndex: 2,
							top: 400,
							width: '100%',
							textAlign: 'center',
						}}
					>
						{searchText != '' || selectedFilters.length > 0 ? (
							<div>
								<Typography variant="h3" color="textPrimary">
									{' '}
									No result found{' '}
								</Typography>
								<Typography color="textSecondary">
									Try different search
								</Typography>
								<img
									src={noSearch}
									alt="No Search"
									width="300px"
								/>
							</div>
						) : (
							<div>
								<Typography variant="h3" color="textPrimary">No Scans available</Typography>

								<img
									src={noViolations}
									alt="No Violations"
									width="300px"
								/>
							</div>
						)}
						</div>
				</>
			) : null}

			{total != 0 && (
				<div
					className={classNames(
						classes['mt_2'],
						isPopup
							? classes['mb_2']
							: classes['bottom_pagination'],
					)}
					style={{ width: width }}
				>
					<TablePagination
						onPageChange={onPageChange}
						page={page}
						pageSize={pageSize}
						total={total}
					></TablePagination>
				</div>
			)}
		</div>
	);
}
