import React from 'react';
import logo from '../static/di-icon.svg';
import logoDark from '../static/di-logo-dark.svg';
import logoLight from '../static/di-logo-light.svg';

export default function DILogo({
	logoOnly = false,
	lightVersion = true,
	size = 512,
}: {
	logoOnly?: boolean;
	lightVersion?: boolean;
	size?: number;
}): React.ReactElement {
	let src;
	if (logoOnly) {
		src = logo;
	} else {
		src = lightVersion ? logoLight : logoDark;
	}

	return <img src={src} alt="Barracuda Data Inspector logo" width={size} />;
}
