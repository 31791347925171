import React from 'react';
import TextField from '@material-ui/core/TextField';

type Variant = "small" | "medium";

type PolicyNameProps = {
  limit: number;
  labelTxt: string;
  variant: Variant;
  value: string;
  setValue: (value: string) => void;
  error: boolean;
  setError: (error: boolean) => void;
  isDefault: boolean;
  defaultError: boolean;
  setDefaultError: (error: boolean) => void;
};

function PolicyName({limit, labelTxt, variant, value, setValue, error, setError, isDefault, defaultError, setDefaultError}: PolicyNameProps) {

  const handleChange = (event) => {
    if (event.target.value.length <= limit) {
      setValue(event.target.value);
    }
  };
  const characterCount = value.length;

  const handleBlur = () => {
    const defaultCheckText = value.toLowerCase().trim();
    if(defaultCheckText === 'default policy') {
      setDefaultError(true);
    }else {
      setDefaultError(false);
    }

    const containsOnlySpace = /^\s+$/.test(value);
    if(value.length === 0 || containsOnlySpace) {
        setError(true);
    }else {
        setError(false);
    }

    setValue(value.trim());
  }

  function getHelperText() {
    if(error) {
      return 'Policy name is required';
    }else if(defaultError) {
      return "Cannot create a policy with name Default policy";
    }else return '';
  }

  return (
    <div style={{display: 'flex', margin: '10px 0px'}}>
    <TextField
      label={labelTxt}
      multiline
      variant="outlined"
      value={value}
      onChange={handleChange}
      onBlur={handleBlur}
      error={error || defaultError}
      helperText={getHelperText()}
      size={variant}
      disabled={isDefault}
      inputProps={{ maxLength: limit }}
      style={{width: '40%'}}
    />
    <p style={{marginTop: 'auto', marginBottom: '0px', fontSize: '10px', color: 'gray', marginLeft: '5px'}}>
        {characterCount} / {limit}
    </p>
    </div>

  );
}

export default PolicyName;