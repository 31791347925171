import { Button, Card, Link,Typography } from '@barracuda-internal/bds-core';
import { Container, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { ReactElement, useState } from 'react';
import FullPageError from 'src/components/FullPageError';
import { useApiRequest } from 'src/hooks/useApi';
import { useAppToolchain } from 'src/hooks/useAppToolchain';
import useAuth from 'src/hooks/useAuth';
import AppLayout from 'src/routes/layouts/AppLayout';

interface Props {
	onAccepted: () => void;
}

const useClasses = makeStyles(() => ({
	root: {
		padding: '32px',
		marginTop: '128px',
	},
	buttonWrapper: {
		margin: '24px 0',
	},
}));

function TermsAndConditions({ onAccepted }: Props): ReactElement {
	const [read, setRead] = useState(false);
	const [doAcceptTerms, setDoAcceptTerms] = useState(false);
	const { api } = useAppToolchain();
	const { auth } = useAuth();
	const classes = useClasses();

	const [updateStatus, res, err] = useApiRequest(
		doAcceptTerms &&
			(() =>
				api.updateUserConfig(auth.user.id, [
					{
						path: '/termsAgreeTime',
						op: 'replace',
						value: new Date().toISOString(),
					},
				])),
		[doAcceptTerms],
		([_, __, err]) => {
			setDoAcceptTerms(false);
			if (!err) {
				onAccepted();
			}
		},
	);

	if (err)
		return (
			<FullPageError message="There was a system error. Please reload and try again." />
		);

	return (
			<Container maxWidth="md">
				<Grid container>
					<Grid item xs={12}>
						<Card className={classes.root}>
							<Typography variant="h2" gutterBottom={true}>
								Please accept the Barracuda terms and conditions
								to continue.
							</Typography>
							<div className={classes.buttonWrapper}>
								<Link
									href="https://www.barracuda.com/company/legal/cloudserviceterms"
									target="_blank"
									onClick={() => setRead(true)}
								>
									<Button
										color={!read ? 'primary' : 'secondary'}
									>
										Open the Terms and Conditions
									</Button>
								</Link>
								<Typography variant="body2">
									&nbsp;&nbsp;&nbsp;(These will be opened in a new tab)
								</Typography>
							</div>
							<div>
								<Button
									isLoading={updateStatus === 'RUNNING'}
									onClick={() => setDoAcceptTerms(true)}
									disabled={!read}
									color='primary'
									variant='contained'
								>
									Accept
								</Button>
							</div>
						</Card>
					</Grid>
				</Grid>
			</Container>
	);
}

export default TermsAndConditions;
