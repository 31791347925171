import { makeStyles } from '@material-ui/core/styles';

const createStyle = (obj,theme,spacing) => {
		obj['p_'+spacing] = {
			padding : theme.spacing(spacing)
		}
		obj['pt_'+spacing] = {
			paddingTop : theme.spacing(spacing)
		}
		obj['pb_'+spacing] = {
			paddingBottom : theme.spacing(spacing)
		}
		obj['ptb_'+spacing] = {
			paddingTop : theme.spacing(spacing),
			paddingBottom : theme.spacing(spacing)
		}
		obj['pl_'+spacing] = {
			paddingLeft : theme.spacing(spacing)
		}
		obj['pr_'+spacing] ={
			paddingRight : theme.spacing(spacing)
		}
		obj['plr_'+spacing] = {
			paddingLeft : theme.spacing(spacing),
			paddingRight : theme.spacing(spacing)
		}
		obj['m_'+spacing] = {
			margin : theme.spacing(spacing)
		}
		obj['mt_'+spacing] = {
			marginTop : theme.spacing(spacing)
		}
		obj['mb_'+spacing] = {
			marginBottom : theme.spacing(spacing)
		}
		obj['mtb_'+spacing] = {
			marginTop : theme.spacing(spacing),
			marginBottom : theme.spacing(spacing)
		}
		obj['ml_'+spacing] = {
			marginLeft : theme.spacing(spacing)
		}
		obj['mr_'+spacing] = {
			marginRight : theme.spacing(spacing)
		}
		obj['mlr_'+spacing] = {
			marginLeft : theme.spacing(spacing),
			marginRight : theme.spacing(spacing)
		}

		obj['icon_'+spacing] = { 
			height : theme.spacing(spacing),
			width : theme.spacing(spacing),
			fontSize : theme.spacing(spacing),
		}
		
}
 

const useStyles = makeStyles((theme) => {

	const style = {};	
	createStyle(style,theme,1);
	createStyle(style,theme,2);
	createStyle(style,theme,3);
	createStyle(style,theme,4);
	createStyle(style,theme,5);
	createStyle(style,theme,6);
	style['m_auto'] = {
		margin : 'auto'
	}
	style['ml_auto'] = {
		marginLeft : 'auto'
	}
	style['w_full'] = {
		width : '100%'
	}
	style['align_center'] = {
		alignItems : 'center'
	}
	style['w_500'] = {
		width : 500
	}
	style['w_720']= {
		width : 720
	}
	style['w_max_500'] = {
		maxWidth : 500
	}
	style['w_max_720']= {
		maxWidth : 720
	}
	style['h_200'] = {
		height : 200
	}
	style['bg_white'] = {
		backgroundColor : '#FFFFFF'
	}
	style['text_white'] = {
		color : '#FFFFFF'
	}
	style['bg_black'] = {
		backgroundColor : '#4B4B4B'
	}
	style['bg_gray'] = {
		backgroundColor : '#FBFBFB'
	}
	style['border_top_gray'] = {
		borderTop : '1px #D0CCC9 solid'
	}
	style['border_bottom_gray'] = {
		borderBottom : '1px #D0CCC9 solid'
	}
	style['border_left_gray'] = {
		borderLeft : '1px #D0CCC9 solid'
	}
	style['border_right_gray'] = {
		borderRight : '1px #D0CCC9 solid'
	}

	style['fx-spacer'] = {
		flex: '1 1 auto'
	}
	style['flex_ver_center'] = {
		display: 'flex',
		alignItems: 'center'
	}
	style['position_relative'] = {
		position: 'relative'
	}

	style['grid'] =  {
		justifyContent: 'center',
		alignItems: 'center',
	}
	style['gridItem'] =  {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	}
	style['d_inline'] =  {
		display: 'inline-block'
	}
	style['d_block'] =  {
		display: 'block'
	}
	style['flex'] =  {
		flex: 1
	}
	style['z_index_0'] = {
		zIndex :0 
	}
	style['bottom_pagination'] = { position :'absolute', bottom : 0, zIndex : 10 , padding : 5 , backgroundColor : 'white'}

	style['side_panel'] = {
		width : 416,
		height : 'calc(100vh - 48px)',
		backgroundColor : '#F7F7F7',
		borderLeft : '1px solid #D0CCC9',
		zIndex : 100,
		overflowX : 'hidden',
		overflowY : 'scroll'
	}
	style['full_panel'] = {
		width : 'calc(100vw - 48px )',
		height : 'calc(100vh - 48px )',
		backgroundColor : '#F7F7F7',
		borderLeft : '1px solid #D0CCC9',
		zIndex : 100,
		overflow : 'auto'
	}

	style['main_container'] = {
		height : '100vh',
		overflow : 'auto'
	}
	style['image_center'] = {
		display : 'block',
		margin : 'auto'
	}
	style['font-small-0.75'] = {
		fontSize: '0.75rem'
	}
	style['cursor_pointer'] = {
		cursor : 'pointer'
	}
	style['cursor_default'] = {
        cursor : 'default'
    }
	style['button_tf_height'] = {
		height : 40
	}
	style['line_height_40'] = {
		lineHeight : '40px'
	}
	style['break_all'] = {
		wordBreak : 'break-all'
	}

	style['di_kendo_grid'] = {
		'& .k-grid td' : {
			borderWidth : '0px 0px 0px 0px'
		},
		'& .k-grid .k-header' : {
			borderRight : 0
		},
		'& .k-grid .k-link' : {
			textTransform : 'capitalize',
			color : '#646464',
			fontWeight : 400,
			fontSize : 14
			
		},
		'& .highlight' :{
			backgroundColor : '#F1FAFF !important',
			cursor : 'pointer'
		},
		'& .highlight .k-grid-content-sticky' :{
			backgroundColor : '#F1FAFF !important',
		},
		'& .k-grid-content-sticky' :{
			backgroundColor : '#FFFFFF',
		},
	}

	style['policy_kendo_grid'] = {
		'& .k-grid td' : {
			borderWidth : '0px 0px 0px 0px'
		},
		'& .k-grid .k-header' : {
			borderRight : 0
		},
		'& .k-grid .k-link' : {
			textTransform : 'none',
			color : '#000 !important',
			fontWeight : '500 !important',
			fontSize : '14px',
			cursor: 'default'
		},
		'& .k-grid th p div' : {
			textTransform : 'none !important',
			color : '#000 !important',
			fontWeight : '500 !important',
			fontSize : '14px',
			cursor: 'default'
		},
	}
	style['text_center'] = {
		textAlign : 'center'
	}
	style['text_right'] = {
		textAlign : 'right'
	}
	style['overflow_hidden'] ={
		oveflow : 'hidden'
	}

	style['setting_button_right'] = {
		position: 'fixed',
		right: 20,
		zIndex: 10,
		marginTop: 7,
		height: '32px !important',
		cursor: 'pointer',
		'& svg' : {
			backgroundColor : 'white',
		}
	}
	style['setting_window'] = {
		position: 'fixed',
		right: 20,
		zIndex: 10,
		paddingTop: 5,
		backgroundColor : 'white',
		boxShadow : '-13px 9px 19px -8px rgba(0,0,0,0.75)'
	}

	style['active_disabled'] = {
		'& input' :{
			'-webkit-text-fill-color' : 'rgba(0,0,0,1) !important'
		}
	}

	style['textarea_highlight'] = {
		'& .DraftEditor-root' :{
			border : '1px lightgray solid',
			padding : '10px 5px',
			minHeight : 60,
			maxHeight : 120,
			overflow : 'auto',
			borderRadius: 4,
			fontSize : 16,
			'& mark' :{
				backgroundColor : '#FFBF00'
			}
		},
		'&.textarea_error .DraftEditor-root' :{
			border : '1px red solid',
		},
		'& .public-DraftEditorPlaceholder-root':{
			display : 'none'
		},
		'& .DraftEditor-editorContainer':{
			padding : '0px 8px'
		}
	}
	style['textarea_error'] = {
		'& .DraftEditor-root' :{
			border : '1px red solid !important',
		}
	}

	style['greyBackground']={
		background:"#f7f7f8"
	}
	
	
	return style;

});

export default  useStyles;