import { Button } from '@barracuda-internal/bds-core';
import { Search as PreviewIcon } from '@material-ui/icons';
import FsLightbox from 'fslightbox-react';
import React, { useState } from 'react';
import { useAppToolchain } from 'src/hooks/useAppToolchain';
import useAPIEffect from 'src/lib/useAPIEffect';
import { Snapshot, Tag } from 'src/types/dataTypes';
import { Typography } from '@barracuda-internal/bds-core';

import useStyles from 'src/styles/di-theme';
import classNames from 'classnames';

export function SecurityReport({
	feedId,
	tagsWithReport,
	detections = false,
}: {
	feedId: string;
	tagsWithReport: Tag[];
	detections?: boolean;
}): React.ReactElement {
	const classes = useStyles();
	const { api } = useAppToolchain();

	const [loadError, setLoadError] = useState();
	const [loadSnapshot, setLoadSnapshot] = useState(false);
	const [loadingSnapshot, setLoadingSnapshot] = useState(false);
	const [openBATDFinding, setOpenBATDFinding] = useState(false);
	const [snapshot, setSnapshot]: [Snapshot, any] = useState();
	const [authedReportUrl, setAuthedReportUrl] = useState<string>();

	const style = {
		backgroundColor: 'white',
	};

	// We don't actually know where the report is, so to find that out
	// we'll have to check the API. We do this when the user clicks the report button.
	useAPIEffect(
		(ignore, signal) => {
			if (loadSnapshot === true) {
				setLoadingSnapshot(true);
				api.getFeedSnapshot(feedId, { signal })
					.then((snapshot) => {
						if (ignore) return;

						setSnapshot(snapshot);
						setOpenBATDFinding(true);
						setLoadError(null);
					})
					.catch((err) => setLoadError(err))
					.finally(() => {
						if (ignore) return;
						setLoadingSnapshot(false);
						setLoadSnapshot(false);
					});
			}
		},
		[loadSnapshot],
	);

	useAPIEffect(
		(ignore, signal) => {
			api.getAccessToken().then((access_token) => {
				const reportUrl = !!snapshot
					? snapshot.findings.filter(
							(f) =>
								!!f.report &&
								(f.type === 'CONTENT_MALICIOUS' ||
									f.type === 'CONTENT_SUSPICIOUS'),
					  )[0].report.url
					: '';

				if (reportUrl) {
					const url = new URL(reportUrl);
					const params = new URLSearchParams(url.search.slice(1));

					// add access token to query params
					params.append('access_token', access_token);

					// add the query params back to the url
					url.search = params.toString();
					setAuthedReportUrl(url.href);
				}
			});
		},
		[snapshot],
	);

	return (
		<div>
			{tagsWithReport.length ? (
				detections ? (
					<Typography
						color="error"
						className={classNames(classes['cursor_pointer'])}
						onClick={() =>
							!!snapshot
								? setOpenBATDFinding(true)
								: setLoadSnapshot(true)
						}
					>
						Security Report
					</Typography>
				) : (
					<Button
						variant="outlined"
						color="primary"
						startIcon={<PreviewIcon />}
						isLoading={loadingSnapshot}
						onClick={() =>
							!!snapshot
								? setOpenBATDFinding(true)
								: setLoadSnapshot(true)
						}
					>
						Security Report
					</Button>
				)
			) : (
				<Typography>N/A</Typography>
			)}

			{loadError ? <p>Failed to load report</p> : ''}

			{openBATDFinding && authedReportUrl?.length ? (
				<FsLightbox
					openOnMount={true}
					sources={[
						<iframe
							src={authedReportUrl}
							style={style}
							width="1920px"
							height="1080px"
							frameBorder="0"
							allow="autoplay; fullscreen"
							allowFullScreen
						></iframe>,
					]}
					toggler={openBATDFinding}
					type="image"
					onClose={() => setOpenBATDFinding(false)}
				/>
			) : (
				''
			)}
		</div>
	);
}
