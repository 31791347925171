import { AppBar } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import BCCAccountsButton from 'src/components/BCC/BCCAccountsButton';
import BCCAccountToolbar from 'src/components/BCC/BCCAccountToolbar';
import TierStatusRow from './TierStatusRow';
import useAuth from 'src/hooks/useAuth';
import { User } from 'src/types/dataTypes';

const useStyles = makeStyles({
	root: {
		backgroundColor: '#162718',
	},
	lightText: {
		color: '#f8f8f8',
	},
	logoWrapper: {
		justifyContent: 'flex-start',
		alignItems: 'center',
		display: 'flex',
	},
});

export default function Header({
	logoutUrl,
	user,
}: {
	logoutUrl: string;
	user: User;
}): React.ReactElement {
	const { auth } = useAuth();
	const bccAccounts =
		auth.accounts.map((a) => ({
			id: a.account.id,
			name: a.account.name,
		})) || [];

	const handleAccountChange = (newAccountId: string) => {
		if (newAccountId === auth.accountDetails.account.id) return;

		const newAccount = auth.accounts.find(
			(a) => a.account.id === newAccountId,
		);

		console.info('changing', newAccountId, newAccount);
		sessionStorage.clear();
		localStorage.setItem('lastActiveAccount', newAccountId);
		localStorage.removeItem('is_setup');
		location.href = '/';
	};

	return (
		<div>
			<AppBar position="static" color="inherit" elevation={0}>
				{!!auth ? (
					<div>
						<BCCAccountToolbar>
							<BCCAccountsButton
								accounts={bccAccounts}
								currentAccountId={
									auth.accountDetails.account.id
								}
								onAccountChange={handleAccountChange}
							/>
						</BCCAccountToolbar>
						{	window.location.pathname !== '/welcome' ? <TierStatusRow></TierStatusRow> : null }
					</div>
				) : null}
			</AppBar>
		</div>
	);
}

