import React, { useState } from 'react';
import {
	Dialog,
	DialogContent,
	DialogActions,
	DialogTitle,
	Typography,
	Checkbox,
	Button,
	
} from '@barracuda-internal/bds-core';

import { FormControlLabel } from '@material-ui/core';
import useStyles from 'src/styles/di-theme';
import { useApiRequest } from 'src/hooks/useApi';
import { useAppToolchain } from 'src/hooks/useAppToolchain';
import { Alert } from '@material-ui/lab';
import { FeedStatus, FeedStatusChangeReason } from 'src/types/dataTypes';
export default function DismissPopup({
	showDismiss = false,
	selectedFeeds,
	closePopup,
	remediateOption,
}) {
	const classes = useStyles();
	const { api } = useAppToolchain();
	const [doRemediate, setDoRemediate] = useState(0);
	const [showConfirm, setShowConfirm] = React.useState(false);
	const [authError, setAuthError] = React.useState(false);
	const [otherError, setOtherError] = React.useState(false);
	const [updateStatus, req, error] = useApiRequest(
		doRemediate &&
			(() => {
				var _remediateStatus: FeedStatus = 'Dismissed';
				var _remediateStatusReason: FeedStatusChangeReason =
					'UserAction';
				switch (remediateOption) {
					case 'Delete':
						_remediateStatus = 'RemediationInitiated';
						_remediateStatusReason = 'UserDeleted';
						break;
					case 'Quarantine':
						_remediateStatus = 'RemediationInitiated';
						_remediateStatusReason = 'UserQuarantined';
						break;
					case 'UnShare':
						_remediateStatus = 'RemediationInitiated';
						_remediateStatusReason = 'UserUnShared';
						break;
					case 'Dismiss':
						_remediateStatus = 'Dismissed';
						_remediateStatusReason = 'UserAction';
						break;
					default:
						_remediateStatus = 'Dismissed';
						_remediateStatusReason = 'UserAction';
						break;
				}
				return api
					.updateBulkFeed(
						selectedFeeds,
						_remediateStatus,
						_remediateStatusReason,
					)
					.then((res) => {
						setAuthError(false);
						setOtherError(false);
						setShowConfirm(false);
						closePopup(true);
					})
					.catch((res) => {
						if (res.response.status == 403) {
							setAuthError(true);
						} else {
							setOtherError(true);
						}
					})
					.finally(() => {});
			}),
		[doRemediate],
		() => {},
	);

	return (
		<div>
			<Dialog open={showDismiss} onClose={() => {}}>
				<DialogTitle>
					<Typography variant="h2">
						Remediate automatically
					</Typography>
				</DialogTitle>
				<DialogContent>
					{otherError ? (
						<Alert severity="error" className={classes['mb_3']}>
							There was an error processing your request. Please
							try again.
						</Alert>
					) : null}
					{authError ? (
						<Alert severity="error" className={classes['mb_3']}>
							Requested action not allowed as permissions required
							to perform this action are not consented. Please
							update your tenant's consented permissions by
							reauthorizing tenant connector from{' '}
							<a href="/settings/connectors">Connectors</a> page.
						</Alert>
					) : null}
					<Typography variant="body1" className={classes['mb_2']}>
						You are about to remediate <b>{selectedFeeds.length}</b>{' '}
						files
					</Typography>
					{remediateOption == 'Delete' ? (
						<Typography variant="body1" className={classes['mb_2']}>
							Remediated files will be deleted from the user's
							drive and moved to Recycle Bin.
						</Typography>
					) : null}
					{remediateOption == 'Quarantine' ? (
						<Typography variant="body1" className={classes['mb_2']}>
							Remediated files will be moved to "DI_QUARANTINE"
							folder which will be present at the root level.
						</Typography>
					) : null}
					{remediateOption == 'UnShare' ? (
						<Typography variant="body1" className={classes['mb_2']}>
							Remediated files will have their sharing permissions
							and links removed and be restricted to Private and
							only the owners will have access.
						</Typography>
					) : null}
					{remediateOption == 'Dismiss' ? (
						<Typography variant="body1" className={classes['mb_2']}>
							No action will be taken on the file in the user's
							drive. The feed will be dismissed and will be shown
							under Resolved detections.
						</Typography>
					) : null}
					{remediateOption != 'Dismiss' ? (
						<FormControlLabel
							control={
								<Checkbox
									onChange={() => {
										setShowConfirm(true);
									}}
									checked={showConfirm}
								></Checkbox>
							}
							label="Continue with your action"
							labelPlacement="end"
						></FormControlLabel>
					) : null}
				</DialogContent>
				<DialogActions className={classes['m_1']}>
					<Button
						disabled={updateStatus === 'RUNNING'}
						color="primary"
						onClick={() => {
							setAuthError(false);
							setOtherError(false);
							setShowConfirm(false);
							closePopup(false);
						}}
					>
						Cancel
					</Button>
					<Button
						isLoading={updateStatus === 'RUNNING'}
						color="primary"
						variant="contained"
						disabled={
							!(remediateOption == 'Dismiss' || showConfirm)
						}
						onClick={() => {
							setDoRemediate(doRemediate + 1);
						}}
					>
						Remediate
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
}
