import React from 'react';
import { LayoutProps } from 'src/routes/layouts/types';

/**
 * Mostly functions as a way of wrapping components with styles.
 * Intended to be used to wrap the entire page, and extended as
 * necessary to fit specific layout needs.
 */
export default function BaseLayout({
	children,
	...rest
}: LayoutProps): React.ReactElement {
	return <main {...rest}>{children}</main>;
}
