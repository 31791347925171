import React, { useState } from 'react';
import { useAppToolchain } from 'src/hooks/useAppToolchain';
import useAPIEffect from 'src/lib/useAPIEffect';
import { Snapshot, Tag } from 'src/types/dataTypes';
import LoadingIndicator from '../../lib/components/LoadingIndicator';

export function FeedConfidenceReport({
	feedId,
	tag,
}: {
	feedId: string;
	tag: Tag;
}) {
	const [feedData, setFeedData]: [Snapshot, any] = useState();
	const [loadError, setLoadError] = useState();
	const { api } = useAppToolchain();

	const style = {
		padding: '15px',
	};

	useAPIEffect((ignore, signal) => {
		api.getFeedSnapshot(feedId, { signal })
			.then((user) => !ignore && setFeedData(user))
			.catch((err) => {
				if (ignore) return;
				else setLoadError(err);
			});
	}, []);

	if (!feedData) {
		return (
			<div>
				<LoadingIndicator />
			</div>
		);
	}

	if (loadError) {
		return <div>There was an error loading this data.</div>;
	}

	if (feedData && !feedData.findings?.length) {
		return <div>No findings details found.</div>;
	}

	// Get just the items with the classification we're looking for
	const filteredFindings = feedData.findings.filter(
		(f) => f.type === tag.classification,
	);

	// Get the finding with the largest liklihood
	let largestLikelihoodItem;
	for (let i in filteredFindings) {
		const item = filteredFindings[i];
		if (
			!largestLikelihoodItem ||
			item['likelihood'] > largestLikelihoodItem['likelihood']
		) {
			largestLikelihoodItem = item;
		}
	}

	return (
		<div>
			<div style={style} key={largestLikelihoodItem.id}>
				Confidence: {`${largestLikelihoodItem.likelihood * 100}%`}
			</div>
		</div>
	);
}
