import { Switch, SwitchLabel, TextField } from '@barracuda-internal/bds-core';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import TextFieldsIcon from '@material-ui/icons/TextFields';
import { Alert } from '@material-ui/lab';
import React, { useState } from 'react';
import FullPageError from 'src/components/FullPageError';
import { useAppToolchain } from 'src/hooks/useAppToolchain';
import useAPIEffect from 'src/lib/useAPIEffect';
import { ClassifierWordsView } from 'src/types/dataTypes';
import ulog from 'ulog';
import EditViewDialog, { EditViewDialogProps } from './EditViewDialog';
import { ClassifierTypes } from 'src/types/dataTypes';
import { validate } from 'src/components/Validation';
const useStyles = makeStyles((theme) => ({
	alert: {
		margin: `0 0 ${theme.spacing(4)} 0`,
	},
	row: {
		borderBottom: 'thin solid lightgray',
		padding: theme.spacing(2),
	},
	optionCell: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'baseline',
	},
}));


interface Props extends EditViewDialogProps {
	accountId: string;
	onSave?: () => {};
}

function EditWordViewDialog({ baseClassifier, onClose, onSave }: Props) {
	const { api } = useAppToolchain();
	const classes = useStyles();

	const [isLoading, setIsLoading] = useState(false);
	const [formState, setFormState] = useState({
		words: '',
		caseSensitive: false,
		displayName: '',
		matchWholeWord: true,
		tagName: '',
		disabled: true,
	});
	const [doSave, setDoSave] = useState(false);
	const [isSaving, setIsSaving] = useState(false);

	const [uiError, setUIError] = useState({
		words: { error: false, msg: '' },
		displayName: { error: false, msg: '' },
		tagName: { error: false, msg: '' },
	  });

	const [classifier, setClassifier]: [
		ClassifierWordsView,
		React.Dispatch<ClassifierWordsView>,
	] = useState();

	const [loadError, setLoadError]: [Error, React.Dispatch<Error>] =
		useState();
	const [saveError, setSaveError]: [Error, React.Dispatch<Error>] =
		useState();

	useAPIEffect((cancelled) => {
		setClassifier(baseClassifier as ClassifierWordsView);
		if (baseClassifier.id != '') {
			api.get<{ data: ClassifierWordsView }>(
				baseClassifier.configUrl,
				{},
				{},
				false,
			)
				.then((res) => {
					if (cancelled) return;

					setClassifier(res.data);
					setFormState({
						words: res.data.words.join('\n').trim(),
						caseSensitive: res.data.caseSensitive,
						matchWholeWord: res.data.matchWholeWord,
						displayName: res.data.displayName || '',
						tagName: res.data.tagName || '',
						disabled: res.data.disabled,
					});
				})
				.catch((err) => {
					ulog.error(err);
					setLoadError(err);
				})
				.finally(() => {
					if (cancelled) return;
					setIsLoading(false);
				});
		}
	}, []);
	function hasUIValidations(uiError: any): boolean {
		const { displayName, tagName , words} = formState;
		uiError.displayName = {
			error: Boolean(validate({ value: displayName })),
			msg: validate({ value: displayName }),
		};
		uiError.tagName = {
			error: Boolean(validate({ value: tagName })),
			msg: validate({ value: tagName }),
		};
		uiError.words = {
			error: Boolean(validate({ value: words })),
			msg: validate({ value: words }),
		};
		setUIError(uiError);
		return Object.values(uiError).some((field: any) => field.error);
	  }
	useAPIEffect(
		(cancelled) => {
			if (!doSave) return;
			if (hasUIValidations(uiError)) {
				setDoSave(false);
				return;
			}

			const patchObj = [
				{
					path: '/displayName',
					op: 'replace',
					value: formState.displayName,
				},
				{
					path: '/words',
					op: 'replace',
					value: formState.words.split('\n'),
				},
				{
					path: '/matchWholeWord',
					op: 'replace',
					value: formState.matchWholeWord,
				},
				{
					path: '/caseSensitive',
					op: 'replace',
					value: formState.caseSensitive,
				},
				{
					path: '/disabled',
					op: 'replace',
					value: formState.disabled,
				},
				{
					path: '/tagName',
					op: 'replace',
					value: formState.tagName,
				},
			];

			setIsSaving(true);
			const save = (id) => {
				if (cancelled) return;
				api.updateWordViewClassifier(classifier.accountId, id, patchObj)
					.then((res1) => {
						if (cancelled) return;
						cancelled = true;
						onSave && onSave();
						onClose && onClose();
					})
					.catch((err) => {
						if (cancelled) return;
						ulog.error(err);
						setSaveError(err);
					})
					.finally(() => {
						if (cancelled) return;
						setDoSave(false);
						setIsSaving(false);
					});
			};
			if (baseClassifier.id == '') {
				api.addClassifierView(
					baseClassifier.accountId,
					baseClassifier.type as ClassifierTypes,
				).then((res) => {
					save(res.id);
				}).catch((err) => { 
					if (cancelled) return;
					ulog.error(err);
					setSaveError(err);
				});
			} else {
				save(baseClassifier.id);
			}
		},
		[doSave],
	);


	const handleTextChange = (e) => {
		setFormState({ ...formState, words: e.target.value });
		const error = validate( { value:  e.target.value, maxlength : 240});
		setUIError((prevState) => ({
			...prevState,
			words: { error: Boolean(error), msg: error },
		  })); 
	};

	const handleDisplayNameChange = (e) => {
		setFormState({ ...formState, displayName: e.target.value });
		const error = validate( { value:  e.target.value});
		setUIError((prevState) => ({
			...prevState,
			displayName: { error: Boolean(error), msg: error },
		  })); 
	};

	const handleLabelInputChange = (e) => {
		setFormState({ ...formState, tagName: e.target.value });
		const error = validate( { value:  e.target.value});
		setUIError((prevState) => ({
			...prevState,
			tagName: { error: Boolean(error), msg: error },
		  })); 
	};

	if (!!loadError)
		return <FullPageError message="An error occured while loading." />;

	return (
		<EditViewDialog
			baseClassifier={baseClassifier}
			Icon={TextFieldsIcon}
			isLoading={isLoading}
			isSaving={isSaving}
			onClose={onClose}
			title="Keyword List"
			onChange={(newFormState) => {
				ulog.debug('Base form state changed', newFormState);
				setFormState({
					...formState,
					...newFormState,
				});
			}}
			onSave={() => setDoSave(true)}
		>
			{!!classifier ? (
				<div>
					{!!saveError ? (
						<Alert className={classes.alert} color="error">
							Error saving form! Please wait a few moments and try
							again.
						</Alert>
					) : null}
					<TextField
						fullWidth
						label="Classifier Name"
						onChange={handleDisplayNameChange}
						style={{ marginBottom: '16px' }}
						value={formState.displayName}
						variant="outlined"
						required
						inputProps={{ maxLength: 64 }}
						error={uiError.displayName.error}
						helperText = {uiError.displayName.msg}
					/>
					<TextField
						label="Label Name"
						helperText={ uiError.tagName.error ? uiError.tagName.msg : "This label will display in your feed when matching text is found."}
						margin="normal"
						onChange={handleLabelInputChange}
						value={formState.tagName}
						variant="outlined"
						required
						inputProps={{ maxLength: 64 }}
						error={uiError.tagName.error}
					/>
					<TextField
						aria-label="keywords"
						fullWidth
						helperText={ uiError.words.error ? uiError.words.msg : "Enter keywords and phrases separated by new lines."}
						label="Keywords"
						margin="normal"
						multiline
						onChange={handleTextChange}
						rows={3}
						rowsMax={10}
						value={formState.words}
						variant="outlined"required
						error={uiError.words.error}
					/>
					<h3>Options</h3>
					<Grid container className={classes.row}>
						<Grid item xs={10}>
							<h4>Case Sensitivity</h4>
							<p>
								When this option is enabled, the classifier will
								only look for text that matches the exact letter
								case as the specified keywords or phrases.
							</p>
							<p>For example, "cat" will not match "Cat".</p>
						</Grid>
						<Grid item xs={2} className={classes.optionCell}>
							<SwitchLabel
								label={formState.caseSensitive ? 'On' : 'Off'}
								labelPlacement="start"
								control={
									<Switch
										checked={formState.caseSensitive}
										onChange={(e) =>
											setFormState({
												...formState,
												caseSensitive: e.target.checked,
											})
										}
									/>
								}
							/>
						</Grid>
					</Grid>
					<Grid container className={classes.row}>
						<Grid item xs={10}>
							<h4>Partial Matching</h4>
							<p>
								When this option is enabled, the classifier will
								look for any text that contains the specified
								keywords or phrases and not just exact matches.
							</p>
							<p>
								For example, "cat" will match both "cat" and
								"caterpillar".
							</p>
						</Grid>
						<Grid item xs={2} className={classes.optionCell}>
							<SwitchLabel
								label={!formState.matchWholeWord ? 'On' : 'Off'}
								labelPlacement="start"
								control={
									<Switch
										checked={!formState.matchWholeWord}
										onChange={(e) =>
											setFormState({
												...formState,
												matchWholeWord:
													!e.target.checked,
											})
										}
									/>
								}
							/>
						</Grid>
					</Grid>
				</div>
			) : null}
		</EditViewDialog>
	);
}

export default EditWordViewDialog;
