import { Skeleton } from '@material-ui/lab';
import React, { ReactElement, useState } from 'react';
import { useAppToolchain } from 'src/hooks/useAppToolchain';
import useAuth from 'src/hooks/useAuth';
import useAPIEffect from 'src/lib/useAPIEffect';
import { CodeusAccountConfig } from 'src/types/dataTypes';
import useStyles from 'src/styles/di-theme';
import classNames from 'classnames';
import { Grid, withWidth } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import AccountRegionSwitcher from 'src/components/AccountRegionSwitcher';
import FEATURES from 'src/featureMap';
import { useApiRequest } from 'src/hooks/useApi';
import {
		Alert,
		Button,
		Dialog,
		DialogContent,
		DialogActions,
		DialogTitle,
		Typography,
		Checkbox,
	} from '@barracuda-internal/bds-core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { DateTime } from 'luxon';
import withAnalytics from 'src/lib/withAnalytics';
import { OnboardingStepProps } from 'src/routes/onboarding/types';
import { error } from 'console';
import { waitFor } from '@testing-library/react';

interface Props {
	noSaveButton?: boolean;
	onChange?: (region: string) => void;
}
	
interface Props {}

const locationMap = {
	US: {
		icon: '🇺🇸',
		text: 'United States',
	},
	CA: {
		icon: '🇨🇦',
		text: 'Canada',
	},
	NL: {
		icon: '🇪🇺',
		text: 'European Union',
	},
	GB: {
		icon: '🇬🇧',
		text: 'Great Britain',
	},
	JP: {
		icon: '🇯🇵',
		text: 'Japan',
	},
	AU: {
		icon: '🇦🇺',
		text: 'Australia',
	},
	IN:{
		icon: '🇮🇳',
		text: 'India',
	}
};

function AccountRegion({onChange}: Props): ReactElement {
	const { api } = useAppToolchain();
	const { auth } = useAuth();
	const classes = useStyles();
	const [selectedRegion, setSelectedRegion] = useState<string>();
	const [warnWin, setWarnWin] = useState(false);
	const [doUpdate, setDoUpdate] = useState(false);
	const handleChangeRegion = () => {setDoUpdate(true);setWarnWin(false);setVisible(false);setAcceptWarn(false)}
	const [visible, setVisible] = useState(false);
	const [acceptWarn, setAcceptWarn] = useState(false);
	const [updateStatus,res,err] = useApiRequest(
		doUpdate &&
			((signal) =>
			{
				
			return	api.saveAccountConfig(
					auth.accountDetails.account.id,
					[
						{
							path: '/storageRegion',
							op: 'replace',
							value: selectedRegion,
						},
					],
					{ signal },
				).then((res) => {
					console.log(config);
					setConfig(res);
					setSelectedRegion(res.storageRegion);
					onChange && onChange(res.storageRegion);if (err) return;
					
					
				})}),
				
		[doUpdate],
		() => {setDoUpdate(false); }
		
	);

	const handleRegionChange = withAnalytics(
		FEATURES.onboardingDataLocationChange,
		(region) => setSelectedRegion(region),
		(region) => {
			return { region };
		},
	);
	
	const onClose = () => {
		setWarnWin(false);
	};
	
	const showDropdown = () => {
		setVisible(true)
	}
	const hideDropdown = () => {
		setVisible(false)
	}
	const [config, setConfig] = useState<CodeusAccountConfig>();

	const [configStatus] = useApiRequest(() => {
		if (!auth) return;

		return api
			.getAccountConfig(auth?.accountDetails.account.id)
			.then((res) => {
				console.log(config);
				setConfig(res);
				setSelectedRegion(res.storageRegion);
				onChange && onChange(res.storageRegion);
			});
	}, [auth]);

	if (!auth || !config) {
		return <Skeleton height={50} animation="wave" />;
	}

	return (
		<>
		<Dialog open={warnWin} onClose={onClose} >
			<Grid item xs={11} className={classes['ml_2']}>
				<DialogTitle className={classes['mt_2']}>
					<Typography variant="h2">Change Data Storage location ?</Typography>
				</DialogTitle>
				
				<DialogContent>
					<Alert  severity='info' className={classes['mb_2']} >
					All new data from now, will go to the new location and all historical data will be moved over as soon as possible.
					</Alert>
					<FormControlLabel
						control={
							<Checkbox
								checked={acceptWarn}
								onChange={(e, checked) =>
									setAcceptWarn(checked)
								}
							/>
						}
						label="Confirm"
					/>
				</DialogContent>
				<DialogActions>
					<Button
						isLoading={updateStatus === 'RUNNING'}
						color="secondary"
						onClick={() => {setWarnWin(false);setAcceptWarn(false)}}
					>
						Cancel
					</Button>
					<Button
					disabled={!acceptWarn}
					color="primary"
					onClick={handleChangeRegion}
					isLoading={updateStatus === 'RUNNING'}
				>
					Save
				</Button>
				</DialogActions></Grid>
			</Dialog>
		<div className='mb_4'>
			<Typography variant="h3" color="textPrimary">
				
				Data Storage Region
				{err ? (
						<Alert color="error">
							An error occured while saving the data location.
							Please reload the page and try again.
						</Alert>
					) : null}
			</Typography>
			<div className={classNames(classes['ptb_3'],classes['ml_2'])}>
				<Typography color="textPrimary">
					{locationMap[config.storageRegion]
						? locationMap[config.storageRegion].icon
						: ''}{' '}
					&nbsp;&nbsp;  Data is stored in {' '}
					{locationMap[config.storageRegion]
						? locationMap[config.storageRegion].text
						: config.storageRegion}
						<Button
						variant="outlined" color="secondary" 
				onClick={() => {showDropdown();}} style={{left:"15px"}}>
					Edit
			</Button>
			
				<Typography><br></br>
					Data Inspector stores your organization's and account information according to your region, ensuring compliance with storage-related regulatory policies.</Typography>
				</Typography>
			</div>
			
			<Grid container style={{top:"30px",left:"20px"}}>
			
			
			{visible && <Grid item xs={12}>
				<AccountRegionSwitcher 
					onChange={handleRegionChange}
				></AccountRegionSwitcher>
				<Button style={{left:"5px"}}
				onClick={() => {hideDropdown();}}>
					Cancel
			</Button>
				<Button
				color="primary"
				disabled={selectedRegion === config.storageRegion}
				onClick={() => {setWarnWin(true);}} style={{marginLeft:"15px",border:"1px"}}>
					Update
			</Button>
			</Grid>}
		</Grid>
		</div>
		</>
	);
}

export default AccountRegion;
