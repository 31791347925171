
import mixpanel from 'mixpanel-browser';
import React, { useEffect, useState } from 'react';
import { useAppToolchain } from 'src/hooks/useAppToolchain';
import { useClasses } from './styles';
import { Redirect } from 'react-router-dom';
import useAPIEffect from 'src/lib/useAPIEffect';
import LoadingIndicator from 'src/lib/components/LoadingIndicator';

export function BCCLogin(): React.ReactElement {
	const { oidcUserManager } = useAppToolchain();
	const toolchain = useAppToolchain();
	const { api } = toolchain;

	useEffect(() => mixpanel.identify(), []);

	const signinRedirect = (provider) => {
		return oidcUserManager.signinRedirect({
			acr_values: ['idp:' + provider],
		});
	};
	const [redirectDetection,setRedirectDetection] = useState(false);
	useAPIEffect((ignore, signal) => {
		api.getLoggedInUser({ signal })
			.then((user) => {
				setRedirectDetection(true);
			})
			.catch((err) => {
				signinRedirect('microsoft')
			})
	}, []);

	const classes = useClasses();

	if (redirectDetection) {
		return <Redirect to="/detection" />;
	}
	 
	return <LoadingIndicator message="Loading" />;
}
