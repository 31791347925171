import React from 'react';
import { DateRangePicker, DateRange } from 'mui-daterange-picker';
import FormControl from '@mui/material/FormControl';

import useStyles from 'src/styles/di-theme';
import moment from 'moment';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import CalendarMonth from '@mui/icons-material/CalendarMonth';
import {
	IconButton,
} from '@barracuda-internal/bds-core';
export default function DIDateRange({
	dateRange,
    setDateRange
}): React.ReactElement {
	

    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const toggle = () => setOpen(!open);
    const definedRanges = [
		{
			label: 'Last Day',
			startDate: moment().subtract(1, 'days').toDate(),
			endDate: moment().toDate(),
		},
		{
			label: 'Last 3 Days',
			startDate: moment().subtract(3, 'days').toDate(),
			endDate: moment().toDate(),
		},
		{
			label: 'Last 7 Days',
			startDate: moment().subtract(7, 'days').toDate(),
			endDate: moment().toDate(),
		},
		{
			label: 'Last 30 Days',
			startDate: moment().subtract(30, 'days').toDate(),
			endDate: moment().toDate(),
		},
		{
			label: 'Last 90 Days',
			startDate: moment().subtract(90, 'days').toDate(),
			endDate: moment().toDate(),
		},
	];
	return <div>
    <FormControl
        sx={{ m: 0, width: '270px' }}
        variant="outlined"
    >
        <InputLabel htmlFor="outlined-adornment-password">
            Date Range
        </InputLabel>
        <OutlinedInput
            id="outlined-adornment-password"
            type="text"
            size="small"
            disabled={true}
            className={classes['active_disabled']}
            value={
                moment(dateRange.startDate).format(
                    'MM/DD/YYYY',
                ) +
                ' - ' +
                moment(dateRange.endDate).format(
                    'MM/DD/YYYY',
                )
            }
            endAdornment={
                <InputAdornment position="end">
                    <IconButton
                        aria-label="Pick Date Range"
                        onClick={toggle}
                        edge="end"
                    >
                        <CalendarMonth />
                    </IconButton>
                </InputAdornment>
            }
            label="Date Range"
        />
    </FormControl>
    <div
        style={{
            position: 'absolute',
            left: 75,
            zIndex: 3,
        }}
    >
        <DateRangePicker
            open={open}
            toggle={toggle}
            maxDate={new Date()}
            minDate={moment()
                .subtract(90, 'days')
                .toDate()}
            definedRanges={definedRanges}
            onChange={(range) => {
                range.startDate = moment(
                    range.startDate,
                )
                    .startOf('day')
                    .toDate();
                range.endDate = moment(
                    range.endDate,
                ).isSame(new Date(), 'day')
                    ? new Date()
                    : moment(range.endDate)
                            .endOf('day')
                            .toDate();
                setDateRange(range);
                setOpen(false);
            }}
        />
    </div>
</div>;
}
