import React from 'react';
import {
	Box,
	Typography,
	Chip,
	Button,
	IconButton,
	Tooltip,
} from '@barracuda-internal/bds-core';

import Refresh from '@mui/icons-material/Refresh';
import SearchField from 'src/components/SearchField';
import Download from '@mui/icons-material/Download';
import FilterList from '@mui/icons-material/FilterList';
import { DateRange } from 'mui-daterange-picker';
import GridPanel from './GridPanel';
import useStyles from 'src/styles/di-theme';
import FilterPanel from 'src/components/FilterPanel';
import { without, union } from 'lodash';
import { useApiRequest } from 'src/hooks/useApi';
import { useAppToolchain } from 'src/hooks/useAppToolchain';
import useAuth from 'src/hooks/useAuth';
import { ESFeedResponse } from 'src/types/dataTypes';
import moment from 'moment';
import classNames from 'classnames';
import FileDownload from 'js-file-download';
import DIDateRange from 'src/components/DIDateRange';
import { useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import filterIcon from '../../static/icon-filter-active.svg';

const localStyles = makeStyles({
	imageIcon: {
		position: 'relative',
		top: -5,
	},
	iconRoot: {
		textAlign: 'center',
	},
});
export default function ScanlogPage() {
	const params: any = useParams();
	const { api } = useAppToolchain();
	const localClasses = localStyles();
	const { auth } = useAuth();
	const [search, setSearch] = React.useState(
		params.id ? 'file:' + params.id : '',
	);
	const [showFilter, setShowFilter] = React.useState(false);
	const [selectedFilters, setSelectedFilters] = React.useState([]);
	const [page, setPage] = React.useState(1);
	const [pageSize, setPageSize] = React.useState(25);
	const [sort, setSort] = React.useState('');
	const [sortDir, setSortDir] = React.useState('desc');
	const classes = useStyles();
	const [dateRange, setDateRange] = React.useState<DateRange>({
		startDate: moment().subtract(30, 'days').toDate(),
		endDate: moment().toDate(),
	});

	const [searchText, setSearchText] = React.useState(
		params.id ? params.id : '',
	);
	const [searchField, setSearchField] = React.useState(
		params.id ? 'file' : '',
	);
	const [refreshGrid, setRefreshGrid] = React.useState(0);
	const [isDownload, setIsDownload] = React.useState(false);
	const searchFields = ['file', 'owner', 'policy', 'action', 'creator'];

	const handleFilterChange = (filter, checked) => {
		let temp = selectedFilters;
		if (checked) {
			temp = union(selectedFilters, [filter]);
		} else {
			temp = without(selectedFilters, filter);
		}
		setPage(1);
		setSelectedFilters(temp);
	};
	const downloadFile = () => {
		setIsDownload(true);
	};
	const getSelectedFilters = () => {
		return selectedFilters;
	};
	const [state, feedResponse, err] = useApiRequest<ESFeedResponse>(() => {
		return api.getAccountESScans(
			auth.accountDetails.account.id,
			dateRange.startDate,
			dateRange.endDate,
			searchText,
			searchField,
			getSelectedFilters(),
			page,
			pageSize,
			sort,
			sortDir,
		);
	}, [
		auth.accountDetails.account.id,
		searchText,
		searchField,
		selectedFilters,
		page,
		pageSize,
		dateRange,
		sort,
		sortDir,
		refreshGrid,
	]);
	const [feedExportStatus] = useApiRequest<string>(
		() =>
			isDownload &&
			api.getAccountDownloadESScans(
				auth.accountDetails.account.id,
				dateRange.startDate,
				dateRange.endDate,
				searchText,
				searchField,
				getSelectedFilters(),
				sort,
				sortDir,
			),
		[isDownload],
		(res) => {
			setIsDownload(false);
			if (res[0] == 'RESOLVED' && res[1]) {
				FileDownload(
					res[1],
					`scanlog_${
						moment(dateRange.startDate).format('MM/DD/YYYY') +
						' - ' +
						moment(dateRange.endDate).format('MM/DD/YYYY')
					}.csv`,
				);
			}
		},
	);
	const [aggState, scanAggregateResponse, aggErr] =
		useApiRequest<ESFeedResponse>(
			() =>
				api.getAccountAggregateESScans(
					auth.accountDetails.account.id,
					dateRange.startDate,
					dateRange.endDate,
					searchText,
					searchField,
					getSelectedFilters(),
				),
			[
				auth.accountDetails.account.id,
				searchText,
				searchField,
				selectedFilters,
				dateRange,
				refreshGrid,
			],
		);
	let totalRecords = 0;
	if (feedResponse) {
		totalRecords = feedResponse.totalCount;
	}
	let gridWidth = 'calc(100vw - 48px)';
	let innerGridWidth = 'calc(100vw - 78px)';
	if (showFilter) {
		gridWidth = 'calc(100vw - 464px)';
		innerGridWidth = 'calc(100vw - 508px)';
	}
	return (
		<>
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'row',
				}}
			>
				<Box sx={{ width: gridWidth }}>
					<div
						className={classNames(
							classes['ml_3'],
							classes['mt_2'],
							classes['mb_3'],
						)}
					>
						<Typography variant="h1" color="textPrimary">
							Scan Log
						</Typography>

						<div className={classes['m_2']}>
							<div className={classes['pb_1']}>
								<Box
									sx={{
										display: 'flex',
										flexDirection: 'row',
									}}
								>
									<DIDateRange
										dateRange={dateRange}
										setDateRange={(range) => {
											setPage(1);
											setDateRange(range);
										}}
									></DIDateRange>
									<Box
										sx={{ width: '350px' , minWidth : '200px' }}
										className={classNames(classes['ml_2'])}
									>
										<SearchField
											searchFields={searchFields}
											setSearchField={setSearchField}
											setSearchText={setSearchText}
											search={search}
											setSearch={setSearch}
											setPage={setPage}
											refresh={0}
										/>
									</Box>
									<div>
										<Tooltip title="Filter">
											{selectedFilters.length > 0 ? (
												<IconButton
													classes={{
														root: localClasses.iconRoot,
													}}
													color="primary"
													onClick={() => {
														setShowFilter(true);
													}}
												>
													<img
														className={
															localClasses.imageIcon
														}
														src={filterIcon}
													/>
												</IconButton>
											) : (
												<IconButton
													className={classNames(
														classes[
															'button_tf_height'
														],
													)}
													onClick={() => {
														setShowFilter(true);
													}}
												>
													<FilterList />
												</IconButton>
											)}
										</Tooltip>
									</div>

									<div>
										<Tooltip title="Export CSV">
											<IconButton
												disabled={
													feedExportStatus ==
													'RUNNING'
												}
												className={classNames(
													classes['button_tf_height'],
												)}
												onClick={() => downloadFile()}
											>
												<Download />
											</IconButton>
										</Tooltip>
									</div>

									<div>
										<Tooltip title="Refresh">
											<IconButton
												disabled={state == 'RUNNING'}
												className={classNames(
													classes['button_tf_height'],
												)}
												onClick={() => {
													let range = dateRange;
													range.endDate = moment(
														range.endDate,
													).isSame(new Date(), 'day')
														? new Date()
														: moment(range.endDate)
																.endOf('day')
																.toDate();
													setDateRange(range);
													setRefreshGrid(
														refreshGrid + 1,
													);
												}}
											>
												<Refresh />
											</IconButton>
										</Tooltip>
									</div>
								</Box>
							</div>

							<div>
								{selectedFilters.map((filter) => (
									<Chip
										className={classes['mr_2']}
										label={filter}
										size="small"
										onDelete={() =>
											handleFilterChange(filter, false)
										}
									/>
								))}
								{selectedFilters.length > 0 ? (
									<Button
										size="small"
										color="primary"
										variant="text"
										onClick={() => setSelectedFilters([])}
									>
										Clear All
									</Button>
								) : null}
							</div>

							<GridPanel
								feeds={feedResponse ? feedResponse.data : []}
								pageSize={pageSize}
								page={page}
								loading={state == 'RUNNING'}
								total={totalRecords}
								onPageChange={(page, pageSize) => {
									setPage(page);
									setPageSize(pageSize);
								}}
								sort={sort}
								sortDir={sortDir}
								onSortChange={(field, dir) => {
									setSort(field);
									setSortDir(dir);
								}}
								width={innerGridWidth}
								searchText={searchText}
								selectedFilters={selectedFilters}
							></GridPanel>
						</div>
					</div>
				</Box>
				{showFilter ? (
					<div className={classes['side_panel']}>
						<FilterPanel
							selected={selectedFilters}
							onSelectionChange={handleFilterChange}
							onClose={() => setShowFilter(false)}
							aggData={scanAggregateResponse}
							filterOptions={[
								{
									category: 'Decision',
									id: 'decision',
									options: ['Violation', 'Clean'],
								},
								{
									category: 'Status',
									id: 'states',
									options: [
										'Finished',
										'Canceled',
										'Deleted',
										'Quarantined',
									],
								},
								{
									category: 'Platform',
									id: 'platforms',
									options: [
										'OneDriveConnector',
										'SharepointLibraryConnector',
									],
								},
							]}
						></FilterPanel>
					</div>
				) : null}
			</Box>
		</>
	);
}
