import { DependencyList, useEffect, useState } from 'react';

export type requestStatus = 'IDLE' | 'RUNNING' | 'RESOLVED' | 'REJECTED';

interface DIRequestError extends Error {
	status?: number;
}

export type arrayResponse<T> = [
	requestStatus,
	T,
	DIRequestError,
	() => void,
	(response: T) => void,
];
export type objectResponse<T> = {
	status: requestStatus;
	result: T;
	error: DIRequestError;
	doCancel: () => void;
	setResponse: (response: T) => void;
};

export type apiResponse<T> = arrayResponse<T> | objectResponse<T>;

// This overload handles returning an array.
export function useApiRequest<T = unknown>(
	request: false | ((signal: AbortSignal) => Promise<T>),
	deps: DependencyList,
	afterEffect?: ((results: arrayResponse<T>) => void) | (() => void),
	returnAsObject?: false,
): arrayResponse<T>;

// This overload handles returning an object.
export function useApiRequest<T = unknown>(
	request: false | ((signal: AbortSignal) => Promise<T>),
	deps: DependencyList,
	afterEffect?: ((results: objectResponse<T>) => void) | (() => void),
	returnAsObject?: true,
): objectResponse<T>;

/**
 * This hook is used to make a request to the API.
 *
 * It automatically handles cancellation on dismount, and provides
 * a function to cancel the request as well.
 *
 * @param request - A function that returns a promise.
 * @param deps - The dependencies for the request.
 * @param afterEffect - A function that is called after the request is resolved.
 * @param returnAsObject - If true, the function returns an object with the status, result, and error.
 * @returns An array or object with the status, result, and error.
 */
export function useApiRequest<T>(
	request: false | ((signal: AbortSignal) => Promise<T>),
	deps: DependencyList = [],
	afterEffect?: (results: apiResponse<T>) => void | (() => void),
	returnAsObject?: boolean,
): apiResponse<T> {
	const [response, setResponse] = useState<T>();
	const [error, setError] = useState<DIRequestError>(null);
	const [status, setStatus] = useState<requestStatus>('IDLE');

	const controller = new AbortController();

	let cancelled = false;
	let doCancel: () => void;

	const res = returnAsObject
		? ({
				status,
				result: response,
				error,
				doCancel,
				setResponse,
		  } as objectResponse<T>)
		: ([
				status,
				response,
				error,
				doCancel,
				setResponse,
		  ] as arrayResponse<T>);

	useEffect(() => {
		// Request can be a boolean, if the calling function does
		// not want the API call to be made.
		if (typeof request !== 'function') return;

		const doFetch = async () => {
			setStatus('RUNNING');
			try {
				const res = await request(controller.signal);
				!cancelled && setResponse(res);
				!cancelled && setStatus('RESOLVED');
			} catch (error) {
				!cancelled && setError(error);
				!cancelled && setStatus('REJECTED');
			}
		};

		doCancel = () => {
			cancelled = true;
			controller.abort();
		};

		doFetch();

		return doCancel;
	}, deps);

	/**
	 * An effect that only runs if:
	 * - Consumer has defined a function to be run.
	 * - Request has resolved or rejected.
	 * - Request was not cancelled at any point.
	 *
	 * This allows the consumer to safely use state within the afterEffect function.
	 *
	 * However: THIS FUNCTION MUST NOT BE USED TO RUN ANOTHER HOOK,
	 * as that would violate the rules of hooks.
	 * This happens because the afterEffect function
	 * is not always called, which will lead to an inconsistent number
	 * of effects called in the render.
	 *
	 * For making a secondary, variable, request:
	 * - Create an effect following the call to useApi
	 *   within the consumer, and trigger it based on useApi status change.
	 */
	useEffect(() => {
		if (
			cancelled ||
			!afterEffect ||
			!(status === 'RESOLVED' || status === 'REJECTED') ||
			typeof request !== 'function'
		)
			return;

		return afterEffect(res);
	}, [status]);

	return res;
}
