import React from 'react';
import {
	DataTable,
	DataTableColumn,
	Typography,
	IconButton
} from '@barracuda-internal/bds-core';

import { GridNoRecords} from '@progress/kendo-react-grid';
import LoadingIndicator from 'src/lib/components/LoadingIndicator';
import { TableCell } from '@material-ui/core';
import TablePagination from 'src/components/TablePagination';
import moment from 'moment';
import useStyles from 'src/styles/di-theme';
import noViolations from 'src/static/no_new_violations.svg';
import classNames from 'classnames';
import ColumnSelector from 'src/components/ColumnSelector';
import Cookies from 'js-cookie';
import { DefaultColumnHeader } from 'src/components/Renderer';
import { TableChart } from '@material-ui/icons';
import noSearch from 'src/static/no_search_violations.svg';

import Button from '@mui/material/Button';
import Popover from '@mui/material/Popover';
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state';

export default function GridPanel({
	feeds,
	loading,
	pageSize,
	page,
	total,
	onPageChange,
	sort,
	sortDir,
	onSortChange,
	width,
	searchText,
	selectedFilters

}) {
	const classes = useStyles();
	const columnCookieKey = 'auditlog_columns';
	const [openColumnSelector, setOpenColumnSelector] = React.useState(false);

	const allColumns = [
		{
			value: 'Date',
			disable: true,
		},
		{
			value: 'Category',
			disable: true,
		},
		{
			value: 'Description',
			disable: false,
		},
		{
			value: 'User',
			disable: false,
		},
		{
			value: 'UserEmail',
			disable: false,
		},
		{
			value: 'Remote IP',
			disable: false,
		},
	];
	const getDefaultColumns = () => {
		let columns = [
			'Date',
			'Category',
			'Description',
			'User',
			'UserEmail',
			'Remote IP',
		];
		if (Cookies.get(columnCookieKey) != null) {
			columns = JSON.parse(Cookies.get(columnCookieKey));
		}
		return columns;
	};
	const [visibleColumns, setVisibleColumns] = React.useState(
		getDefaultColumns(),
	);

	return (
		<div className={classes['di_kendo_grid']}>
			<IconButton
				className={classes['setting_button_right']}
				onClick={() => {
					setOpenColumnSelector(true);
				}}
			>
				<TableChart />
			</IconButton>
			{openColumnSelector ? (
				<div className={classes['setting_window']}>
					<ColumnSelector
						allColumns={allColumns}
						selectedColumns={visibleColumns}
						onClose={() => {
							setOpenColumnSelector(false);
						}}
						onSelectionChange={(selection) => {
							Cookies.set(
								columnCookieKey,
								JSON.stringify(selection),
							);
							setVisibleColumns(selection);
						}}
					></ColumnSelector>
				</div>
			) : null}

			<div
				style={{
					maxHeight: 'calc(100vh - 240px)',
					width: width,
					overflow: 'auto',
				}}
			>
				{loading ? (
					<div
						style={{
							maxHeight: 'calc(100vh - 300px)',
							height: 'calc(100vh - 300px)',
						}}
					>
						<LoadingIndicator message="Loading ..." />
					</div>
				) : (
					<DataTable
						data={feeds}
						onSortChange={(e) => {
							if (e.sort.length > 0) {
								onSortChange(e.sort[0].field, e.sort[0].dir);
							} else {
								onSortChange('', '');
							}
						}}
						sort={[{ field: sort, dir: sortDir }]}
						sortable={true}
						style={{
							border: 0
						}}
					>
						<GridNoRecords>&nbsp;</GridNoRecords>

						{visibleColumns.indexOf('Date') != -1 ? (
							<DataTableColumn
								cell={({ dataItem }) => (
									<TableCell>
										<Typography
											variant="body1"
											color="textPrimary"
											noWrap
											component="span"
										>
											{moment(
												dataItem.creationTime,
											).format('MMM, DD')}
										</Typography>
										<Typography
											variant="body1"
											color="textSecondary"
											className={classes['pl_1']}
											noWrap
											component="span"
										>
											{moment(
												dataItem.creationTime,
											).format(' LT')}
										</Typography>
									</TableCell>
								)}
								field="creationTime"
								title="Date"
								width="160px"
							/>
						) : null}
						{visibleColumns.indexOf('Category') != -1 ? (
							<DataTableColumn
								cell={({ dataItem }) => (
									<TableCell>
										<Typography
											variant="body1"
											color="textPrimary"
											noWrap
										>
											{dataItem.category}
										</Typography>
									</TableCell>
								)}
								field="category"
								title="Category"
								width="150px"
							/>
						) : null}
						{visibleColumns.indexOf('Description') != -1 ? (
							<DataTableColumn
								cell={({ dataItem }) => (
									<TableCell>
										<Typography
											variant="body1"
											color="textPrimary"
											style={{position: 'relative'}}
										>
											{dataItem.description.slice(0, 90)}
											{
												dataItem.description.length > 90 && 
												<PopupState variant="popover" popupId="desc-popup-popover">
													{(popupState) => (
														<span>
															<Button
																sx={{
																	all: 'unset',
																	padding: 0,
																	margin: '0px 5px',
																	border: 'none',
																	cursor: 'pointer',
																	'&:hover': {
																		backgroundColor: 'inherit'
																	  },
																  }}
																{...bindTrigger(popupState)}>
																...
															</Button>
															<Popover
																{...bindPopover(popupState)}
																anchorOrigin={{
																vertical: 'bottom',
																horizontal: 'center',
																}}
																transformOrigin={{
																vertical: 'top',
																horizontal: 'center',
																}}
															>
																<Typography
																	style={{
																		width: '900px',
																		padding: '20px',
																		maxHeight: '200px',
																		overflowY: 'scroll'
																	}}
																>
																	{dataItem.description}
																</Typography>
															</Popover>
														</span>
													)}
												</PopupState>
											}
										</Typography>
									</TableCell>
								)}
								field="description"
								sortable={false}
								headerCell={DefaultColumnHeader}
								title="Description"
								width="400px"
							/>
						) : null}
						{visibleColumns.indexOf('User') != -1 ? (
							<DataTableColumn
								cell={({ dataItem }) => (
									<TableCell>
										<Typography
											variant="body1"
											color="textPrimary"
											noWrap
										>
											{dataItem.userName}
										</Typography>
									</TableCell>
								)}
								field="userName"
								title="User"
								width="200px"
							/>
						) : null}
						{visibleColumns.indexOf('UserEmail') != -1 ? (
							<DataTableColumn
								cell={({ dataItem }) => (
									<TableCell>
										<Typography
											variant="body1"
											color="textPrimary"
											noWrap
										>
											{dataItem.userEmail}
										</Typography>
									</TableCell>
								)}
								field="userEmail"
								title="User Email"
								width="300px"
							/>
						) : null}
						{visibleColumns.indexOf('Remote IP') != -1 ? (
							<DataTableColumn
								cell={({ dataItem }) => (
									<TableCell>
										<Typography
											variant="body1"
											color="textPrimary"
											noWrap
										>
											{dataItem.ip}
										</Typography>
									</TableCell>
								)}
								field="ip"
								title="Remote IP"
								sortable={false}
								headerCell={DefaultColumnHeader}
								width="150px"
							/>
						) : null}
					</DataTable>
				)}
			</div>
			{total == 0 && !loading ? (
				<>
					<div
						style={{
							position: 'fixed',
							zIndex: 2,
							top: 400,
							width: '100%',
							textAlign: 'center',
						}}
					>
						{searchText != '' || selectedFilters.length > 0 ? (
							<div>
								<Typography variant="h3" color="textPrimary">
									{' '}
									No result found{' '}
								</Typography>
								<Typography color="textSecondary">
									Try different search
								</Typography>
								<img
									src={noSearch}
									alt="No Search"
									width="300px"
								/>
							</div>
						) : (
							<div>
								<Typography variant="h3" color="textPrimary">No Audit logs</Typography>

								<img
									src={noViolations}
									alt="No Violations"
									width="300px"
								/>
							</div>
						)}

						
					</div>
				</>
			) : null}

			{total != 0 && (
				<div className={classNames(classes['mt_2'], classes['bottom_pagination'])} style={{width:width}}>
					<TablePagination
						onPageChange={onPageChange}
						page={page}
						pageSize={pageSize}
						total={total}
					></TablePagination>
				</div>
			)}
		</div>
	);
}
