import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { createPortal } from 'react-dom';
import LoadingIndicator from 'src/lib/components/LoadingIndicator';

const useStyles = makeStyles(() => ({
	'@global': {
		'.k-grid-container': {
			minHeight: '200px',
		},
	},
	root: {
		position: 'absolute',
		width: '100%',
		top: 0,
		height: '100%',
		backgroundColor: 'white',
		opacity: 0.8,
	},
	wrapper: {
		minHeight: '150px',
		position: 'relative',
	},
}));

export default function TableMask({ message = 'Loading...', open }) {
	if (!open) return null;

	const classes = useStyles();
	const loadingPanel = (
		<div className={classes.root}>
			<div className={classes.wrapper}>
				<LoadingIndicator message={message} />
			</div>
		</div>
	);

	const gridContent = document && document.querySelector('.k-grid-content');
	return gridContent ? createPortal(loadingPanel, gridContent) : null;
}