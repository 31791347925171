import * as React from 'react';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { Chip, debounce } from '@mui/material';
import { useAppToolchain } from 'src/hooks/useAppToolchain';
import useAuth from 'src/hooks/useAuth';

type userData = {
  id: string;
  name: string | null;
  email?: string | null;
  remoteId?: string | null;
  barracudaId?: string | null;
}

type SelectedUsers = {
  current: userData[];
};

type ExceptionProps = {
  selectedUsers: SelectedUsers
}

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

function ExceptionUsers({selectedUsers}: ExceptionProps) {

  const [data, setData] = React.useState([]);
  const [selectedTags, setSelectedTags] = React.useState(() => selectedUsers.current.map(user => ({ id: user.id, name: user.name })));
  const [inputValue, setInputValue] = React.useState('');
  const { api } = useAppToolchain();
  const { auth } = useAuth();

  React.useEffect(() => {
    performSearch('');
    
    return () => {
      setData([]);
    }
  }, []);

  function convertData(data) {
    return data.reduce((acc, curr) => {
        if(!acc[curr.id]) {
            acc[curr.id] = curr;
        }
        return acc;
    }, {})
  }

  const performSearch = React.useMemo(
    () => debounce((query) => {
        api.getPolicyUsers(
          auth.accountDetails.account.id,
          query
        ).then(res => {
          if(res) {
            const hash = convertData([...selectedUsers.current, ...res.data.map(ele => ele.user)]);
            setData(Object.values(hash));
          }
        });
    }, 500),
    [api, auth.accountDetails.account.id]
  );

  const handleInputChange = (e, value) => {
    const query = value.trimStart();
    setInputValue(query);
    performSearch(query);
  }

  const handleBlur = () => {
    if(inputValue.length > 0) {
      performSearch('');
    }
    setInputValue('');
  }

  const checkOptionSelected = (option) => {
    const obj = selectedTags.find(user => user.id === option.id);
    return obj?.id === option.id;
  }

  const filterOptions = (options) => {
    return options;
  };

  return (
    <>
      <Autocomplete
        multiple
        id="checkboxes-tags-demo"
        options={data}
        limitTags={1}
        clearOnBlur={false}
        getLimitTagsText={(tags) => <span style={{fontSize: '12px', color: 'gray', fontWeight: 500}}>{`+${tags} more`}</span>}
        disableCloseOnSelect
        value={selectedTags.map(user => data.find(option => option.id === user.id))}
        getOptionLabel={(option) => option?.name || ''}
        onInputChange={handleInputChange}
        filterOptions={filterOptions}
        inputValue={inputValue}
        onChange={(_, newValue) => {
          setInputValue(inputValue);
          setSelectedTags(newValue.map(user => ({ id: user.id, name: user.name })));
          selectedUsers.current = newValue;
        }}

        renderOption={(props, option) => {
          return (
            <div key={option?.id}>
              <li style={{ marginLeft: '12px' }} {...props}>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8 }}
                      checked={checkOptionSelected(option)}
                      sx={{
                        color: 'black',
                        '&.Mui-checked': {
                          color: 'black',
                        },
                      }}
                    />
                    {option?.name}
            </li>
            </div>
          )
        }}
        size='small'
        renderInput={(params) => {
          return (
              <TextField {...params} 
                label="Users"
                InputLabelProps={{
                  style: { fontSize: '10px', fontWeight: 'bold', lineHeight: 2 }
                }}
                onBlur={handleBlur}
              />
          )
          }}
        renderTags={(values, getTagProps) => {
          if(values[0] == null) return [];
          const renderedTags = [];
          values.forEach((option, index) => {
                renderedTags.push(
                  <Chip 
                  sx={{
                    fontSize: '10px',
                    height: '20px',
                    fontWeight: 500
                  }}
                  size="small"
                  key={option?.id} label={option?.name} {...getTagProps({ index })} />
                );
              });
          return renderedTags;
        }}       
      />
    </>
  );
}

export default ExceptionUsers;