import * as React from 'react';
import { styled, alpha } from '@mui/material/styles';
import Menu, { MenuProps } from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import classNames from 'classnames';
import { Button, Divider } from '@barracuda-internal/bds-core';
import useStyles from 'src/styles/di-theme';
import { Typography } from '@barracuda-internal/bds-core';
const StyledMenu = styled((props: MenuProps) => (
	<Menu
		elevation={0}
		anchorOrigin={{
			vertical: 'bottom',
			horizontal: 'right',
		}}
		transformOrigin={{
			vertical: 'top',
			horizontal: 'right',
		}}
		{...props}
	/>
))(({ theme }) => ({
	'& .MuiPaper-root': {
		borderRadius: 6,
		marginTop: theme.spacing(1),
		minWidth: 120,
		color:
			theme.palette.mode === 'light'
				? 'rgb(55, 65, 81)'
				: theme.palette.grey[300],
		boxShadow:
			'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
		'& .MuiMenu-list': {
			padding: 0,
		},
		'& .MuiMenuItem-root': {
			padding : '16px 24px',
			'& .MuiSvgIcon-root': {
				fontSize: 18,
				color: theme.palette.text.secondary,
				marginRight: theme.spacing(1.5),
			},
			'&:active': {
				backgroundColor: alpha(
					theme.palette.primary.main,
					theme.palette.action.selectedOpacity,
				),
			},
		},
	},
}));

export default function DropDownMenu({ label, menus, disabled = false }) {
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
	const open = Boolean(anchorEl);
	const handleClick = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};
	const classes = useStyles();

	return (
		<div>
			<Button
				id="dropdown-button"
				aria-controls={open ? 'dropdown-menu' : undefined}
				aria-haspopup="true"
				aria-expanded={open ? 'true' : undefined}
				size="small"
				variant="contained"
				color="primary"
				disableElevation
				onClick={handleClick}
				className={classNames(
					classes['ml_2'],
					classes['button_tf_height'],
				)}
				disabled={disabled}
				endIcon={<KeyboardArrowDownIcon />}
			>
				{label}
			</Button>
			<StyledMenu
				id="dropdown-menu"
				MenuListProps={{
					'aria-labelledby': 'dropdown-button',
				}}
				anchorEl={anchorEl}
				open={open}
				onClose={handleClose}
			>
				{menus.map((menu, index) => {
					if (menu.type == 'menu') {
						return (
							<MenuItem
								key={index}
								onClick={() => {
									handleClose();
									menu.onClick();
								}}
								disableRipple
							>
								<Typography
									variant="body1"
									color="textPrimary"
									noWrap
								>
									{menu.label}
								</Typography>
							</MenuItem>
						);
					}
					if (menu.type == 'divider') {
						return <Divider key={index}></Divider>;
					}
				})}
			</StyledMenu>
		</div>
	);
}
