import React, { ReactElement } from 'react';
import { withRequireOnboarding } from 'src/components/withRequireOnboarding';
import PolicyEnginePage from './policyengine/Main';


export default function PolicyEngineRoute(): ReactElement {
	const Page = withRequireOnboarding(PolicyEnginePage);
	// const Page = PolicyEnginePage;

	return (
			<Page/>
	);
}