import { Typography, Chip } from '@barracuda-internal/bds-core';
import { TableCell } from '@material-ui/core';
import { DateTime } from 'luxon';
import React from 'react';
import { forOwn } from 'lodash';
import useStyles from 'src/styles/di-theme';
import classNames from 'classnames';
import { RowContext } from './GridPanel';
import { DIHtmlTooltip } from 'src/components/TooltipColumn';
export const getTagStyle = (tag) => {
	let tagStyle = {
		margin: '2px',
		background: '#E4F4FF',
		border: '1px #7CBDE6 solid',
		color: 'black',
	};
	let textColor = 'black';

	let color = null;
	switch (tag) {
		case 'Malicious':
		case 'Suspicious': {
			color = '#DE3131'; // Red
			textColor = 'white !important';
			break;
		}
		case 'Credit Card':
		case 'Credentials':
		case 'Passport':
		case 'Passport':
		case 'License Number':
		case 'Personal ID':
		case 'Tax ID':
		case 'MICR Code':
		case 'Personal Medical ID':
		case 'Financial ID': {
			color = '#66DAEE'; //Powder Blue
			break;
		}
		case 'External Readable':
		case 'External Writeable':
		case 'Public Readable':
		case 'Public Writeable': {
			color = '#F2EB7B'; // Yellow
			break;
		}
		default: {
			color = '#C8C8C7'; //Gray
			break;
		}
	}

	if (color) {
		tagStyle = {
			margin: '2px',
			background: color,
			border: '1px solid ' + color,
			color: textColor,
		};
	}

	return { tagStyle, textColor: { color: textColor } };
};

const getTagContent = (tagText: string, count: number) => {
	const classes = useStyles();
	if (tagText.length > 19) {
		return (
			<DIHtmlTooltip
				ttcontent={
					<Typography
						variant="body1"
						color="textSecondary"
						className={classNames(classes['d_inline'])}
					>
						<Typography
							variant="body1"
							key={tagText + ' ' + count}
							className={classNames(classes['p_1'])}
						>
							{tagText + ' ' + count}
						</Typography>
					</Typography>
				}
				content={
					<Typography
						variant="body1"
						color="textSecondary"
						className={classNames(
							classes['d_inline'],
							classes['cursor_pointer'],
						)}
					>
						{tagText.slice(0, 16) + '... ' + count}
					</Typography>
				}
			></DIHtmlTooltip>
		);
	} else {
		return tagText + ' ' + count;
	}
};
export function TagRenderer({ dataItem: { tags } }) {
	return (
		<div>
			{tags.map((tag, index) => (
				<Chip
					key={index}
					color="primary"
					variant="default"
					label={getTagContent(tag.text, tag.count)}
					size="small"
					style={getTagStyle(tag.text).tagStyle}
				/>
			))}
		</div>
	);
}

function MoreRenderer({ tags }) {
	if (tags.length == 0) return <></>;
	const classes = useStyles();
	return (
		<>
			<DIHtmlTooltip
				ttcontent={
					<Typography
						variant="body1"
						color="textSecondary"
						className={classNames(classes['d_inline'])}
					>
						{tags.map((t) => (
							<Typography
								variant="body1"
								key={t}
								className={classNames(classes['p_1'])}
							>
								{t}
							</Typography>
						))}
					</Typography>
				}
				content={
					<Typography
						variant="body1"
						color="textSecondary"
						className={classNames(
							classes['d_inline'],
							classes['cursor_pointer'],
						)}
					>
						{' '}
						+{tags.length} more
					</Typography>
				}
			></DIHtmlTooltip>
		</>
	);
}
export function TagRendererCell({ dataItem }) {
	let tagsText = [];
	let count = 0;
	let moreTags = [];
	forOwn(dataItem.tags, (value, tag) => {
		if (count < 3) {
			tagsText.push(
				<Chip
					color="primary"
					variant="default"
					label={getTagContent(tag, value)}
					key={tag + ' ' + value}
					size="small"
					style={getTagStyle(tag).tagStyle}
				/>,
			);
		} else {
			moreTags.push(tag + ' ' + value);
		}
		count++;
	});

	return (
		<RowContext.Consumer>
			{(handleRowClick: any) => (
				<td onClick={() => handleRowClick({ dataItem })}>
					{tagsText.map((t) => t)}{' '}
					<MoreRenderer tags={moreTags}></MoreRenderer>
				</td>
			)}
		</RowContext.Consumer>
	);
}

export function UserRendererCell({ dataItem }) {
	let tagsText = [];
	let count = 0;
	let moreTags = [];
	const classes = useStyles();
	dataItem.ownerNames.forEach((value) => {
		if (count < 1) {
			tagsText.push(
				<Typography
					variant="body1"
					key={value}
					className={classNames(classes['p_1'], classes['d_inline'])}
				>
					{' '}
					{value}
				</Typography>,
			);
		} else {
			moreTags.push(value);
		}
		count++;
	});

	return (
		<RowContext.Consumer>
			{(handleRowClick: any) => (
				<td onClick={() => handleRowClick({ dataItem })}>
					{tagsText.map((t) => t)}{' '}
					<MoreRenderer tags={moreTags}></MoreRenderer>
				</td>
			)}
		</RowContext.Consumer>
	);
}

export function DateCell({ dataItem }) {
	return (
		<RowContext.Consumer>
			{(handleRowClick: any) => (
				<TableCell onClick={() => handleRowClick({ dataItem })}>
					<div
						style={{
							flexDirection: 'column',
							textAlign: 'left',
							textTransform: 'none',
						}}
					>
						<Typography variant="body1" color="textPrimary" noWrap>
							{DateTime.fromISO(dataItem.created).toLocaleString(
								DateTime.DATETIME_MED,
							)}
						</Typography>
					</div>
				</TableCell>
			)}
		</RowContext.Consumer>
	);
}

export function StatusRenderer(dataItem, tab) {
	let action = '';
	switch (dataItem.statusReason) {
		case 'UserDeleted':
		case 'PolicyDeleted':
			action = tab == 'Resolved' ? 'Deleted' : 'Deleting';
			break;
		case 'UserQuarantined':
		case 'PolicyQuarantined':
			action = tab == 'Resolved' ? 'Quarantined' : 'Quarantining';
			break;
		case 'UserUnShared':
		case 'PolicyUnShared':
			action = tab == 'Resolved' ? 'Unshared' : 'Unsharing';
			break;
		case 'UserAction':
			action = tab == 'Resolved' ? 'Dismissed' : 'Dismissing';
			break;
		default:
			action = tab == 'Resolved' ? 'Dismissed' : 'Dismissing';
			break;
	}
	return (
		<TableCell>
			<Chip
				style={{
					margin: '2px',
					background: '#EDE3F6',
					border: '0px',
					color: 'black',
				}}
				color="primary"
				variant="default"
				clickable={false}
				label={
					<>
						<Typography component="span">{action}</Typography>
					</>
				}
				size="small"
			/>
		</TableCell>
	);
}
