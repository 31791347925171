import { makeStyles } from '@material-ui/core/styles';
import bg from 'src/static/di-large.jpg';

export const useClasses = makeStyles({
	loginText: {
		fontWeight: 'bold',
		textDecoration: 'underline',
		cursor: 'pointer',
	},
	root: {
		width: '100vw',
		height: '100vh',
		background: `url(${bg}) no-repeat`,
		backgroundPosition: 'center center',
		backgroundSize: 'cover',
	},
	sidebar: {
		position: 'relative',
		height: '100vh',
		maxWidth: '560px',
		backgroundColor: '#162618',
		color: 'white',
		display: 'flex',
		flexDirection: 'column',
		padding: '36px',
	},
	buttonWrapper: {
		marginTop: '32px',
		marginBottom: '32px',
	},
	diLogo: {
		display: 'flex',
		flex: '0 0 128px',
		justifyContent: 'center',
	},
	languageBar: {
		display: 'flex',
		flex: '0 0 32px',
	},
	loginPrompt: {
		color: 'white',
		display: 'flex',
		flex: 1,
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
	},
	cudaLogo: {
		display: 'flex',
		flex: '0 0 180px',
		justifyContent: 'center',
		alignItems: 'center',
	},
	classLogo: {
		width: '160px',
	},
	cookieBanner: {
		backgroundColor: 'white',
		color: 'black',
		padding: '8px 32px',
		width: '80%',
		margin: '16px auto',
		position: 'relative',
		textAlign: 'center',
	},
	cookieBannerClose: {
		position: 'absolute',
		right: 0,
		width: '24px',
		height: '24px',
		top: '32px',
	},
});
