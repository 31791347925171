import { useState, useEffect, SetStateAction, Dispatch } from 'react';

/**
 * Runs an effect after N milliseconds.
 *
 * The effect may be rendered with `run` set to false, in which case the effect
 * will not run. The effect must be rendered even if it is not intended to run
 * to ensure that hooks are always called in the same order.
 * (See: https://reactjs.org/docs/hooks-rules.html)
 *
 * The effect returns a an array containing:
 * - A boolean that states whether the timer has been reached.
 * - A function that can be used to stop the timer at any point.
 *
 * @param timeout - Time (in milliseconds) before the timer should elapse.
 * @param run - If set to false, the timer will not run.
 */
export function useTimeout(timeout = 1000, run = true): [boolean, () => void] {
	const [timeoutHit, setTimeoutHit]: [
		boolean,
		Dispatch<SetStateAction<boolean>>,
	] = useState(false);

	let timer;
	const startTimer = () => {
		timer = setTimeout(() => {
			setTimeoutHit(true);
		}, timeout);
	};

	// If this function is not called, the timer will always trigger
	// a re-render. This is why we pass it out, so that the consumer
	// can cancel their timer once the thing they are timing is completed.
	const stopTimer: (handle?: number) => void = () => {
		clearTimeout(timer);
	};

	useEffect(() => {
		if (run) {
			startTimer();
		}

		return stopTimer;
	}, [run]);

	return [timeoutHit, stopTimer];
}

export default useTimeout;
