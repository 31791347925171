import { Typography } from '@barracuda-internal/bds-core';
import { Alert } from '@material-ui/lab';
import React, { ReactElement } from 'react';
import { Redirect } from 'react-router-dom';
import { useApiRequest } from 'src/hooks/useApi';
import { useAppToolchain } from 'src/hooks/useAppToolchain';
import useAuth from 'src/hooks/useAuth';
import LoadingIndicator from 'src/lib/components/LoadingIndicator';
import { EmailSettingsForm } from 'src/routes/settings/email/EmailSettingsForm';
import { CodeusAccountState } from 'src/types/dataTypes';
import useStyles from 'src/styles/di-theme';
import classNames from 'classnames';
interface Props {}

function EmailSettings({}: Props): ReactElement {
	const { auth } = useAuth();
	const { api } = useAppToolchain();
	const classes = useStyles();

	const [status, tierState, err] = useApiRequest<CodeusAccountState>(
		(signal) =>
			api.getAccountTierState(auth.accountDetails.account.id, {
				signal,
			}),
		[],
	);

	if (!auth?.isFullAdmin()) {
		return <Redirect to="/settings/"></Redirect>;
	}

	if (!tierState) return <LoadingIndicator />;

	if (err) {
		return (
			<Alert severity="error">
				An error occured while loading settings.
			</Alert>
		);
	}

	return (
		<div
			className={classNames(
				classes['pt_4'],
				classes['plr_4'],
			)}
		>
			{tierState.state === 'Free' ? (
				<Alert severity="info">
					Notifications are disabled for accounts in free mode.
				</Alert>
			) : (
				<>
					<Typography
						variant="h1"
						color="textPrimary"
						className={classes['mb_2']}
					>
						Notifications
					</Typography>
					<EmailSettingsForm />
				</>
			)}
		</div>
	);
}

export default EmailSettings;
