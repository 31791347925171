import * as React from 'react';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { Chip } from '@mui/material';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;


type SelectedAuthorships = {
  current: string[];
};

type AuthorshipProps = {
  selectedAuthorship: SelectedAuthorships;
  error: boolean;
  setError: (error: boolean) => void;
}

function Authorship({selectedAuthorship, error, setError}: AuthorshipProps) {
    const [data, setData] = React.useState(["Owner", "Creator", "LastModifier"]);
    const [selectedTags, setSelectedTags] = React.useState(selectedAuthorship.current);

    const handleBlur = () => {
      if(selectedTags.length === 0) {
        setError(true);
      }else {
        setError(false);
      }
    }
  return (
    <>
      <Autocomplete
        multiple
        options={data}
        disableCloseOnSelect
        value={selectedTags}
        getOptionLabel={(option) => option}
        onChange={(_, newValue) => {
          setSelectedTags(newValue);
          selectedAuthorship.current = newValue;
        }}
        renderOption={(props, option) => (
          <>
            <li key={option} {...props}>
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 8 }}
                checked={selectedTags.includes(option)}
                sx={{
                  color: 'black',
                  '&.Mui-checked': {
                    color: 'black',
                  },
                }}
              />
              {option}
            </li>
          </>
        )}
        size='small'
        renderInput={(params) => (
          <TextField
            {...params}
            label="Authorship"
            InputLabelProps={{
              style: { fontSize: '10px', fontWeight: 'bold', lineHeight: 2 }
            }}
            onBlur={handleBlur}
            error={error}
            helperText={error ? 'Atleast one authorship must be selected' : ''}
          />
        )}
        renderTags={(values, getTagProps) =>
          values.map((option, index) => (
            <Chip
              key={option}
              sx={{
                fontSize: '10px',
                height: '20px',
                fontWeight: 500
              }}
              size="small"
              label={option}
              {...getTagProps({ index })}
            />
          ))
        }
      />
    </>
  );
}

export default Authorship;