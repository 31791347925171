{
	"name": "web-ui",
	"version": "1.0.0",
	"license": "UNLICENSED",
	"browserslist": [
		"since 2017-06"
	],
	"alias": {
		"src": "./src"
	},
	"scripts": {
		"build": "export REACT_APP_VERSION=$(sentry-cli releases propose-version) && yarn run parcel build index.html",
		"check": "npx tsc --noEmit --watch --strictBindCallApply",
		"clean": "rm -rf ./dist",
		"config": "yarn http-server -p 3001 --cors",
		"dev": "export REACT_APP_VERSION=$(sentry-cli releases propose-version) && yarn run parcel index.html -p 443 --https",
		"devprod": "yarn http-server dist/ -p 3002 --cors",
		"start": "yarn dev",
		"test": "NODE_ENV=testing yarn jest --watch --coverage",
		"web-ui": "yarn start"
	},
	"dependencies": {
		"@babel/cli": "^7.17.6",
		"@babel/core": "^7.0.0-0",
		"@babel/preset-env": "^7.9.0",
		"@babel/preset-react": "^7.9.4",
		"@babel/preset-typescript": "^7.16.7",
		"@barracuda-internal/bds-core": "0.11.5",
		"@cuda/bds.icons.barracuda-icon": "^0.0.1",
		"@cuda/bds.ui.navigation.navigation-bar": "^0.0.8",
		"@cuda/bds.ui.navigation.profile-avatar": "^0.0.2",
		"@cuda/bds.ui.navigation.profile-menu": "^0.0.2",
		"@emotion/react": "^11.10.5",
		"@emotion/styled": "^11.10.5",
		"@material-ui/core": "^4.12.3",
		"@material-ui/icons": "^4.11.2",
		"@material-ui/lab": "^4.0.0-alpha.45",
		"@material-ui/pickers": "^3.3.10",
		"@material-ui/utils": "^4.11.2",
		"@microsoft/applicationinsights-react-js": "^3.0.0",
		"@microsoft/applicationinsights-web": "^2.3.1",
		"@parcel/reporter-bundle-analyzer": "^2.1.1",
		"@sentry/react": "^6.15.0",
		"@sentry/tracing": "^6.15.0",
		"@testing-library/jest-dom": "^5.11.10",
		"@testing-library/react": "^12.1.2",
		"@types/jest": "^27.1.2",
		"@types/node": "^13.1.7",
		"@types/react": "^16.8.6 || ^17.0.0",
		"@types/react-router-dom": "^5.1.7",
		"@typescript-eslint/eslint-plugin": "^3.5.0",
		"@typescript-eslint/parser": "^3.5.0",
		"@progress/kendo-react-grid": "^3.18.0",
		"axios": "^1.7.2",
		"babel-jest": "^25.1.0",
		"babel-plugin-transform-imports": "^2.0.0",
		"babel-plugin-transform-runtime": "^6.23.0",
		"babel-preset-jest": "^25.1.0",
		"buffer": "^6.0.3",
		"chart.js": "^4.4.2",
		"chartjs-adapter-date-fns": "^3.0.0",
		"colors": "^1.4.0",
		"core-js": "3",
		"draft-js": "^0.11.7",
		"eslint": "^7.3.1",
		"eslint-config-prettier": "^6.11.0",
		"eslint-plugin-react": "^7.20.3",
		"events": "^3.3.0",
		"fetch-mock-jest": "^1.1.0-beta.3",
		"fetch-ponyfill": "^6.1.0",
		"fslightbox-react": "^1.4.9",
		"http-server": "^0.12.1",
		"jest": "^27.4.7",
		"jest-fetch-mock": "^3.0.3",
		"jest-junit": "^12.0.0",
		"js-cookie": "^2.2.1",
		"js-file-download": "^0.4.12",
		"jwt-decode": "^3.1.2",
		"lib": "^4.0.5",
		"lodash": "^4.17.20",
		"luxon": "^1.21.3",
		"material-ui-popup-state": "^2.0.0",
		"mixpanel-browser": "^2.40.1",
		"moment": "^2.29.4",
		"mui-daterange-picker": "^1.0.5",
		"oidc-client": "^1.11.5",
		"parcel": "^2.5.0",
		"prettier": "^2.0.5",
		"process": "^0.11.10",
		"react": "^17.0.2",
		"react-chartjs-2": "^5.2.0",
		"react-dnd": "^16.0.1",
		"react-dnd-html5-backend": "^16.0.1",
		"react-dom": "^17.0.0",
		"react-dropzone": "^11.2.4",
		"react-ga": "^2.7.0",
		"react-gravatar": "^2.6.3",
		"react-helmet": "^6.1.0",
		"react-highlight-within-textarea": "^3.2.1",
		"react-image-magnifiers": "^1.4.0",
		"react-intercom": "^1.0.15",
		"react-keyed-flatten-children": "^1.3.0",
		"react-router-dom": "^5.2.0",
		"react-test-render": "^1.1.2",
		"regenerator-runtime": "^0.13.3",
		"sass": "^1.25.0",
		"ts-jest": "^27.1.2",
		"typescript": "^4.5.4",
		"ulog": "^1.1.0"
	},
	"devDependencies": {
		"@parcel/transformer-sass": "^2.5.0",
		"vm-browserify": "^1.1.2"
	}
}
