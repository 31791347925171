import { colors } from '@barracuda-internal/bds-core';
import makeStyles from '@material-ui/core/styles/makeStyles';

export default makeStyles((theme) => {
	const barPadding = {
		[theme.breakpoints.up('sm')]: {
			paddingLeft: `max(${theme.spacing(
				2,
			)}px, env(safe-area-inset-left))`,
			paddingRight: `max(${theme.spacing(
				2,
			)}px, env(safe-area-inset-right))`,
			fallbacks: {
				paddingLeft: theme.spacing(2),
				paddingRight: theme.spacing(2),
			},
		},
		[theme.breakpoints.down('xs')]: {
			paddingLeft: `max(${theme.spacing(
				1,
			)}px, env(safe-area-inset-left))`,
			paddingRight: `max(${theme.spacing(
				1,
			)}px, env(safe-area-inset-right))`,
			fallbacks: {
				paddingLeft: theme.spacing(1),
				paddingRight: theme.spacing(1),
			},
		},
	};

	return {
		accountBar: {
			display: 'flex',
			minHeight: 'auto',
			background: colors.barracudaBlack,
			justifyContent: 'flex-end',
			'& > :first-child': { marginRight: 'auto' },
			paddingTop: 0,
			...barPadding,
			button: {
				'Mui-disabled': { color: 'white' },
				'&:hover': { 'Mui-disabled': { backgroundColor: 'inherit' } },
			},
		},
		productBar: {
			display: 'flex',
			background: colors.barracudaBlack70,
			justifyContent: 'flex-end',
			'& > :first-child': { marginRight: 'auto' },
			paddingTop: 0,
			...barPadding,
		},
		footerBar: {
			display: 'flex',
			flexDirection: 'row',
			justifyContent: 'flex-start',
			alignItems: 'flex-end',
			color: colors.barracudaBlack70,
			paddingTop: '8px',
			paddingBottom: 'max(10px, env(safe-area-inset-bottom))',
			...barPadding,
			fallbacks: { paddingBottom: '8px', ...barPadding.fallbacks },
		},
		footerText: {
			flex: 1,
			display: 'flex',
			flexDirection: 'column',
		},
		footerRights: { display: 'span', '& > span': { whiteSpace: 'nowrap' } },
		footerLinks: {
			display: 'span',
			whiteSpace: 'nowrap',
			'& > a': { color: colors.barracudaBlue, textDecoration: 'none' },
			'& > a:hover': {
				color: colors.barracudaBlue60,
				textDecoration: 'underline',
			},
			'&>:first-child': { display: 'none' },
		},
		footerLogo: { xalignSelf: 'flex-end' },
		linkDivider: { color: colors.barracudaBlack30, padding: '0 8px' },
	};
});
