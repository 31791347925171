import React from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

const options = [
  { value: 'Ignore classifier', label: 'Ignore classifier', description: 'No action will be taken to the specified classifier(s) but the file will continue to be checked against other policies.' },
  { value: 'Report file', label: 'Report file', description: 'File will be shown on the Data Inspector detections unresolved tab.' },
  { value: 'Quarantine file', label: 'Quarantine file', description: 'File will be moved to the "DI_QUARANTINE" folder which will be present at the root level.' },
  { value: 'UnShare file', label: 'UnShare file', description: "File will have its sharing permissions and links removed and be restricted to Private and only the owners will have access." },
  { value: 'Delete file', label: 'Delete file', description: "File will be deleted from the user's drive and moved to Recycle Bin" }
];

type SelectedAction = {
  current: string[];
};


type ActionsProps = {
  selectedAction: SelectedAction;
}

function Actions({selectedAction}: ActionsProps) {
  selectedAction.current = selectedAction.current.length > 0 ? selectedAction.current: [options[1].value];
  const [selectedValue, setSelectedValue] = React.useState(selectedAction.current[0]);
  const handleChange = (event) => {
    setSelectedValue(event.target.value);
    selectedAction.current = [event.target.value];
  };

  return (
    <FormControl fullWidth>
    <InputLabel id="demo-simple-select-label" style={{fontSize: '11px', fontWeight: 'bold'}}>Action</InputLabel>
        <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            size='small'
            value={selectedValue}
            onChange={handleChange}
            label='Action'
            style={{width: '300px'}}
            sx={{ fontSize: '14px'}}
            renderValue={(selected) => {
                const selectedOption = options.find(option => option.value.startsWith(selected));
                return selectedOption ? selectedOption.label : '';
            }}
            >
            {options.map(option => (
                <MenuItem key={option.value} value={option.value} style={{width: '300px'}}>
                    <div>
                        <span style={{fontSize: '14px', fontWeight: 500, display: 'inline-block'}}>{option.label}</span>
                        <br/>
                        <span style={{fontSize: '0.8em', color: 'gray', fontWeight: 500, whiteSpace: 'normal' }}>{option.description}</span>
                    </div>
                </MenuItem>
            ))}
        </Select>
      </FormControl>
  );
}

export default Actions;