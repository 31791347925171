import { makeStyles } from '@material-ui/core/styles';
import { BaseCSSProperties } from '@material-ui/core/styles/withStyles';
import React, { ReactElement, ReactNode } from 'react';

interface Props {
	children: ReactNode;
	direction?: CenterDirections;
	flexDirection?: BaseCSSProperties['flexDirection'];
	horizontal?: boolean;
	vertical?: boolean;
}

export type CenterDirections = 'both' | 'horizontal' | 'vertical';

const useStyles = makeStyles<
	null,
	{
		direction?: CenterDirections;
		flexDirection?: BaseCSSProperties['flexDirection'];
		horizontal?: boolean;
		vertical?: boolean;
	}
>(() => ({
	root: ({ flexDirection, vertical, direction, horizontal }) => {
		let style = {
			display: 'flex',
			flexDirection: flexDirection || 'column',
		};

		if (vertical || direction === 'both') {
			style['justifyContent'] = 'center';
			style['height'] = '100%';
		}

		if (horizontal || direction === 'both') {
			style['alignItems'] = 'center';
		}

		return style;
	},
}));

function Center({
	children,
	direction = 'both',
	flexDirection = 'column',
	horizontal = true,
	vertical = false,
}: Props): ReactElement {
	const classes = useStyles({
		direction,
		flexDirection,
		horizontal,
		vertical,
	});
	return <div className={classes.root}>{children}</div>;
}

export default Center;
