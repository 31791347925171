import mixpanel from 'mixpanel-browser';
import React, { useEffect } from 'react';
import Helmet from 'react-helmet';
import FEATURES from 'src/featureMap';
import BaseLayout from 'src/routes/layouts/BaseLayout';
import { version } from '../../package.json';
import { LoginSidebar } from './login/LoginSidebar';
import { useClasses } from './login/styles';

export default function LoginLandingPage(): React.ReactElement {
	const classes = useClasses();

	useEffect(() => {
		const action = FEATURES.pageChange;
		mixpanel.track(`DIN ${action.id}`, {
			urlPath: location.pathname,
			feature: action.feature,
			appVersion: version,
		});
	}, [location]);

	return (
		<BaseLayout>
			<>
				<Helmet>
					<title>Sign in to Barracuda Data Inspector</title>
				</Helmet>
				<main className={classes.root}>
					<LoginSidebar />
				</main>
			</>
		</BaseLayout>
	);
}