import { useContext } from 'react';
import { AppToolchainContext } from 'src/components/providers/AppToolchainProvider';
import { AppToolchain } from 'src/types/applicationTypes';

// let _toolchain;
// export function _setupToolchainHook(
// 	toolchain: AppToolchain,
// 	forceUpdate = false,
// ) {
// 	if (_toolchain && !forceUpdate)
// 		throw Error('Tried to re-instantiate existing toolchain.');
// 	_toolchain = Object.freeze(toolchain);
// }

export function useAppToolchain(): AppToolchain {
	return useContext(AppToolchainContext);
}
