import React, { ReactElement } from 'react';
import { withRequireOnboarding } from 'src/components/withRequireOnboarding';
import AuditLogPage from './auditlog/Main';

interface Props {}

export default function AuditlogRoute({}: Props): ReactElement {
	const Page = withRequireOnboarding(AuditLogPage);

	return (
			<Page/>
	);
}
