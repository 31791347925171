export async function get(
	url: RequestInfo,
	params = null,
	options?: RequestInit,
): Promise<Response> {
	let query = '';
	if (params !== null) {
		if (params instanceof Object) {
			Object.entries(params).forEach(([k]) => {
				if (params[k] === undefined || params[k] === null)
					delete params[k];
			});
		}
		const search =
			params instanceof URLSearchParams
				? params
				: new URLSearchParams(params);
		query = search.toString();
	}

	if (query) query = `?${query}`;

	return fetch(`${url}${query}`, {
		...options,
		method: 'GET',
	});
}

export async function del(
	url: RequestInfo,
	options?: RequestInit,
): Promise<Response> {
	return fetch(`${url}`, {
		...options,
		method: 'DELETE',
	});
}

export async function patch(
	url: RequestInfo,
	params = {},
	options?: RequestInit,
): Promise<Response> {
	return fetch(`${url}`, {
		...options,
		method: 'PATCH',
		body: JSON.stringify(params),
	});
}

export async function post(
	url: RequestInfo,
	params = {},
	options?: RequestInit,
): Promise<Response> {
	return fetch(`${url}`, {
		...options,
		method: 'POST',
		body: JSON.stringify(params),
	});
}

export async function put(
	url: RequestInfo,
	params = {},
	options?: RequestInit,
): Promise<Response> {
	return fetch(`${url}`, {
		...options,
		method: 'PUT',
		body: JSON.stringify(params),
	});
}

export type HttpVerb = 'PUT' | 'GET' | 'POST' | 'DELETE';
