import DescriptionIcon from '@material-ui/icons/Description';
import TextFieldsIcon from '@material-ui/icons/TextFields';
import EditTextFileDialog from './EditTextFileDialog';
import EditWordViewDialog from './EditWordViewDialog';
import EditRegexDialog from './EditRegexDialog';

const classifierMap = {
	WordsFindingView: {
		name: 'Keyword Classifier',
		icon: TextFieldsIcon,
		dialog: EditWordViewDialog,
	},
	TextFileFindingView: {
		name: 'Keyword File Classifier',
		icon: DescriptionIcon,
		dialog: EditTextFileDialog,
	},
	CustomRegexFindingView: {
		name: 'Regex Classifier',
		icon: DescriptionIcon,
		dialog: EditRegexDialog,
	}
}; 

export default classifierMap;
