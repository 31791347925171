import { Alert } from '@material-ui/lab';
import React, { ReactElement, useState } from 'react';
import { useApiRequest } from 'src/hooks/useApi';
import { useAppToolchain } from 'src/hooks/useAppToolchain';
import Grid from '@material-ui/core/Grid';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

import { Typography } from '@barracuda-internal/bds-core';
import useStyles from 'src/styles/di-theme';
interface Props {}

function RequestPIConsent({}: Props): ReactElement {
	const [consent, setConsent] = useState(false);
	const [doConsent, setDoConsent] = useState(false);
	const [first, setFirst] = useState(false);
	const { api } = useAppToolchain();
	const classes = useStyles();

	const [updateStatus, req, error] = useApiRequest(
		doConsent &&
			(() =>
				api.updateUserConfig('current', [
					{
						path: '/personalInfoCollectionConsented',
						op: 'replace',
						value: consent,
					},
				])),
		[doConsent],
		() => {
			setDoConsent(false);
		},
	);

	useApiRequest(
		(signal) => api.getLoggedInUser({ signal }),
		[first],
		(resp) => {
			let user = resp[1];
			setConsent(user.config.personalInfoCollectionConsented);
		},
	);

	let handleChange = (e, checked) => {
		setConsent(checked);
		setDoConsent(true);
	};

	return (
		<div>
			<Typography
				variant="h3"
				color="textPrimary"
				className={classes['mb_4']}
			>
				Personal Information Consent
			</Typography>
			{error ? (
				<Alert color="error">
					There was an error saving the form. Please try again.
				</Alert>
			) : null}
			<div className={classes['ml_2']}>
				<Typography
					variant="h4"
					color="textPrimary"
					className={classes['mb_4']}
				>
					Consent confirmation
				</Typography>
				<Grid container spacing={1}>
					<Grid item xs={3}>
						<FormControlLabel
							control={
								<Switch
									name="checkedB"
									color="primary"
									onChange={handleChange}
									checked={consent}
								/>
							}
							label="Accepted"
						/>
					</Grid>
					<Grid item xs={9}>
					<Typography
							variant="body1"
							color="textPrimary"
							className={classes['mb_2']}
						>
							Barracuda Data Inspector can collect your name and
							email address for the purpose of providing product
							support and communicating product news and
							announcements. Please see our{' '}
							<a
								href="https://www.barracuda.com/company/legal/trust-center/data-privacy"
								target="_blank"
							>
								Trust Center
							</a>{' '}
							for information on how we keep your data secure.
						</Typography>
					</Grid>
				</Grid>
			</div>
		</div>
	);
}

export default RequestPIConsent;
