import { Box, Button, Typography } from '@material-ui/core';
import React, { createContext, useRef, useState } from 'react';
import useStyles from 'src/styles/di-theme';
import classNames from 'classnames';
import AddIcon from '@mui/icons-material/Add';
import SortIcon from '@mui/icons-material/Sort';
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import GridPanel from './GridPanel';
import { useApiRequest } from 'src/hooks/useApi';
import { useAppToolchain } from 'src/hooks/useAppToolchain';
import useAuth from 'src/hooks/useAuth';
import Drawer from '@mui/material/Drawer';
import { PolicySettings } from './PolicySettings';
import PolicySearchField from './PolicySearchField';
import { Link } from 'react-router-dom';

export const PolicyContext = createContext(null);

type Anchor = 'top' | 'left' | 'bottom' | 'right';

const PolicyEnginePage = () => {
  const classes = useStyles();
  const { api } = useAppToolchain();
  const { auth } = useAuth();

  const [drawer, setDrawer] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer =
    (anchor: Anchor, open: boolean) =>
    (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event &&
        event.type === 'keydown' &&
        ((event as React.KeyboardEvent).key === 'Tab' ||
          (event as React.KeyboardEvent).key === 'Shift')
      ) {
        return;
      }

      setDrawer({ ...drawer, [anchor]: open });
    };

  const [searchText, setSearchText] = useState('');
  const [search, setSearch] = useState('');

  const [policyChange, setPolicyChange] = useState(false);
  const prevFeedsResponse = useRef([]);
  const [policyFeeds, setPolicyFeeds] = useState([]);
  const [selectedPolicyToModify, setSelectedPolicyToModify]= useState({});
  const createPolicyClicked = useRef(false);
  const [hideConsent, setHideConsent] = useState(true);
  const [modifyClicked, setModifyClicked] = useState(false);

  const [status] = useApiRequest(
    () =>
      api.getPolicyEngineFeeds(
        auth.accountDetails.account.id,
        search
      ),
    [
      auth.accountDetails.account.id,
      search
    ],
		(response) => {
      const [_, policyData] = response
			setPolicyFeeds(policyData?.data);
      prevFeedsResponse.current = policyData?.data;
		},
	);

  useApiRequest(
    () =>
      api.getConsent(
        auth.accountDetails.account.id,
      ),
    [],
		(response) => {
      const [_, flag] = response;
      setHideConsent(flag);
		},
	);

  const handleResetPriority = () => {
    setPolicyChange(!policyChange);
    setPolicyFeeds(prevFeedsResponse.current);
  }

  const handleSavePriority = () => {
    setPolicyChange(!policyChange);

    const priorityChange = policyFeeds.reduce((acc, curr, index) => {
      if(curr.id !== prevFeedsResponse.current[index].id) {
        acc.push({
            id: curr.id,
            priority: index + 1
          }) 
      }
      return acc;
    }, []);

    if(priorityChange.length === 0) return;
    prevFeedsResponse.current = policyFeeds;

    api.updatePolicyEngineFeedPriorities(
      auth.accountDetails.account.id,
      priorityChange
    );
  }

  const handleCreatePolicy = (e) => {
    createPolicyClicked.current = true;
    toggleDrawer('right', true)(e);
    setSelectedPolicyToModify({});
    setModifyClicked(false);
  }

  return (
    <PolicyContext.Provider 
      value={{
        policyChange, 
        prevFeedsResponse,
        selectedPolicyToModify,
        setSelectedPolicyToModify,
        toggleDrawer,
        createPolicyClicked,
        modifyClicked,
        setModifyClicked
      }}
    >
      {!hideConsent && <div 
        style={{
          textAlign: 'center',
          backgroundColor: '#FBEAEA',
          color: '#581313',
          padding: 8
        }}
      >
        Policies for this account will be limited to users. Please <Link to='/settings/connectors'>reauthorise the tenant</Link> to allow Data Inspector to read groups data.
      </div>}
      <Box
        className={classNames(
          classes['m_3'],
        )}
      >
        <Box 
          sx={{
            display: 'flex',
            alignItems: 'center'
          }}
          className={classNames(
            classes['mb_3']
          )}
        >
          <Typography variant="h1" color="textPrimary">
            {!policyChange && 'Policies'}
            {policyChange && 'Edit policy priorities'}
          </Typography>
          <div 
            className={classNames(
              classes['ml_auto']
            )}
            style={{display: 'flex', alignItems: 'center'}}
          >
            {!policyChange && <Button className={classNames(
                classes['cursor_pointer']
              )}
              color="secondary"
					    variant="text"
              onClick={() => setPolicyChange(!policyChange)}
            >
              <SortIcon fontSize='small' className={classNames(classes['mr_1'])} /> Edit Priority
            </Button>}
            {!policyChange && <div style={{backgroundColor: '#bdbdbd',
                                    marginLeft: '35px',
                                    marginRight: '35px',
                                    width: '2px',
                                    height: '40px'}}>
                                  </div>}
            {!policyChange && <Button className={classNames(
                classes['cursor_pointer']
              )}
              color="primary"
					    variant="contained"
              onClick={handleCreatePolicy}
            >
              <AddIcon fontSize='small' className={classNames(classes['mr_1'])} /> Create Policy
            </Button>}

            {policyChange && <Button className={classNames(
                classes['m_2'],
                classes['cursor_pointer']
              )}
              color="secondary"
					    variant="text"
              onClick={handleResetPriority}
            >
              Cancel
            </Button>}
            {policyChange && <Button className={classNames(
                classes['cursor_pointer']
              )}
              color="primary"
					    variant="contained"
              onClick={handleSavePriority}
            >
             Save
            </Button>}

          </div >
        </Box>
        <PolicySearchField
          setSearch={setSearch}
          searchText={searchText}
          setSearchText={setSearchText}
          label='Search'
          searchFields={'name'}
        />

        <DndProvider backend={HTML5Backend}>
          <GridPanel policyFeeds={policyFeeds ? policyFeeds : []} setPolicyFeeds={setPolicyFeeds} status={status}/>
        </DndProvider>

        <React.Fragment>
          <Drawer
            anchor='right'
            open={drawer['right']}
            onClose={toggleDrawer('right', false)}
          >
            <PolicySettings anchor={'right'} toggleDrawer={toggleDrawer} setPolicyFeeds={setPolicyFeeds} />
          </Drawer>
        </React.Fragment>
      </Box>
    </PolicyContext.Provider>
  )
}

export default PolicyEnginePage;