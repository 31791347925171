import React, { useCallback, useContext, useEffect, useState } from 'react';
import Shimmer from 'src/components/Shimmer';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import PreviewToolTip from './PreviewToolTip';
import { SideDetailContext } from './SideDetailPanel';

const shimmerWidth = [100, 70, 90, 80, 60, 80, 60, 50, 40, 30];

export const ImageLoader = ({preview, index, selectedClassiferObj, myRef, generatePreviewWidthAndHeight, imageRefs, zoom, setSelectedClassifierObj, getLocation, selectedClassifer, imageSpecs, classifierIdx, previews, setShowFBPopup}) => {

	const [showShimmer, setShowShimmer] = useState(true);
	const [showError, setShowError] = useState(false);
	const [url, setUrl] = useState('');
	const [previewFindings, setPreviewFindings] = useState([]);
	const {showRedactions} = useContext(SideDetailContext);
	const [image,setImage] = useState(null);

	useEffect(() => {
		setUrl(showRedactions ? preview.redactedURL : preview.revealedURL);
	}, [preview, showRedactions]);

	useEffect(() => {
		setShowShimmer(true);
	}, [preview]);


    useEffect(() => {
		if( image != null ){
			setPreviewFindings(getPreviewFindings(preview.previewFindings, image.naturalWidth, image.naturalHeight));
		}
		
    }, [zoom]);

	const pickFindingOnLoad = useCallback((image) => {
		let counter = 0;

		outerLoop: for(const preview of previews) {
			for(const finding of preview.previewFindings) {
				if (finding.findingTag == selectedClassifer) {
					counter++;
					if (counter == classifierIdx) {
						let obj: any = getLocation(
							finding,
							zoom,
							preview.pixelDensity,
							image.naturalWidth,
							image.naturalHeight,
						);
						obj.border = showRedactions ? '3px solid #FFEB3B' : '3px solid #FCAD36';
						obj.borderRadius = '5px';
						obj.page = preview.page;
						setSelectedClassifierObj(obj);
						break outerLoop;
					}
				}
			}
		}

	}, [showRedactions, selectedClassifer, classifierIdx]);

	const getPreviewFindings = (previewFindings, width, height) => {
		return previewFindings.map(finding => ({...finding, ...getLocation(finding, zoom, preview.pixelDensity, width, height)}));
	}
	
	return(
		<div ref={imageRefs[index]} data-alt={index + 1} style={{position: 'relative'}}>
			{(showShimmer || showError) && <div>
                <div
                    style={{
                        width: 600,
                        height: 600,
                        maxWidth: 600,
                        display : 'block',
                        margin : '2px auto',
                        backgroundColor: '#fff',
                        padding: '30px',
						position: 'relative'
                    }}
                >
					{showError && 
						<Stack sx={{ width: '100%' }} spacing={2}>
							<Alert severity="error">
								Failed to load the image. Please try again after few minutes.
							</Alert>
						</Stack>
					}
                    <Shimmer 
                        shimmerWidth={shimmerWidth} 
                        skeletonHeight={30} 
                        animation = "wave"
                        style={{ marginBottom: 20, marginTop: 20}}
                    />
                </div>
			</div>
			} 
			{url.length > 0 && <div >
				{selectedClassiferObj != null && !showError &&
				selectedClassiferObj.page == preview.page ? (
					<div style={{ position : 'relative', height : 0,width : 0, scrollMarginTop : 500, top : selectedClassiferObj.top, left :selectedClassiferObj.left  }}>
						{!showError && !showShimmer && <div data-alt={index + 1} style={selectedClassiferObj} ref={myRef}></div>}
					</div>
				) : <></>}
					{
						previewFindings.length > 0 && !showRedactions &&
						previewFindings.map((finding) => (
							<PreviewToolTip 
								finding={finding} 
								key={finding.fidingId} 
								previewIndex={index}
								setShowFBPopup={setShowFBPopup} 
								previewUrl={preview.url || ''} 
							/>
						))
					}
					 <img
						width={zoom * 6}
						src={url}
						key={index}
						onLoad={(event) => {
							const image = event.target;
							generatePreviewWidthAndHeight(image.naturalWidth, image.naturalHeight, preview.id);
							if(!imageSpecs[preview.id] && selectedClassiferObj.page == preview.page ) {
								pickFindingOnLoad(image);
							}
							setShowShimmer(false);
							setShowError(false);
							setPreviewFindings(getPreviewFindings(preview.previewFindings, image.naturalWidth, image.naturalHeight));
							setImage(image);
						}}
						onError={() => {
							setShowError(true);
							setShowShimmer(true);
						}}
					></img>
			</div>}
		</div>

	)
}