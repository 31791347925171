import React, { useState } from 'react';
import {
	Dialog,
	DialogContent,
	DialogActions,
	DialogTitle,
	Typography,
	Button,
} from '@barracuda-internal/bds-core';
import moment from 'moment';
import { DateRange } from 'mui-daterange-picker';
import useStyles from 'src/styles/di-theme';
import { useAppToolchain } from 'src/hooks/useAppToolchain';
import { useApiRequest } from 'src/hooks/useApi';
import { ESFeedResponse } from 'src/types/dataTypes';
import useAuth from 'src/hooks/useAuth';
import GridPanel from '../scanlog/GridPanel';
export default function ScanLogPopup({
	showScanlog = false,
	closePopup,
	searchText,
    itemId
}) {
	const classes = useStyles();
	const { api } = useAppToolchain();
    const { auth } = useAuth();
    const [sort, setSort] = React.useState('');
	const [sortDir, setSortDir] = React.useState('desc');
    const [dateRange, setDateRange] = React.useState<DateRange>({
		startDate: moment().subtract(90, 'days').toDate(),
		endDate: moment().toDate(),
	});
	const [page, setPage] = React.useState(1);
	const [pageSize, setPageSize] = React.useState(10);
	const [state, feedResponse, err] = useApiRequest<ESFeedResponse>(() => {
		return api.getAccountESScans(
			auth.accountDetails.account.id,
			dateRange.startDate,
			dateRange.endDate,
			'',
			'',
			['ItemId:'+itemId],
			page,
			pageSize,
			sort,
			sortDir,
		);
	}, [
		auth.accountDetails.account.id,
		page,
		pageSize,
		sort,
		sortDir,
	]);
    let totalRecords = 0;
	if (feedResponse) {
		totalRecords = feedResponse.totalCount;
	}
	return (
		<div>
			<Dialog maxWidth="lg" open={showScanlog} onClose={() => {}}>
				<DialogTitle>
					<Typography variant="h2">
						Scan Log for "{searchText}"
					</Typography>
				</DialogTitle>
				<DialogContent>
                <GridPanel
						feeds={feedResponse ? feedResponse.data : []}
						pageSize={pageSize}
						page={page}
						loading={state == 'RUNNING'}
						total={totalRecords}
						onPageChange={(page, pageSize) => {
							setPage(page);
							setPageSize(pageSize);
						}}
						sort={sort}
						sortDir={sortDir}
						onSortChange={(field, dir) => {
							setSort(field);
							setSortDir(dir);
						}}
						isPopup={true}
					></GridPanel>
				</DialogContent>
				<DialogActions className={classes['m_1']}>
					<Button
						color="primary"
						onClick={() => {
							closePopup(false);
						}}
					>
						Close
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
}
