import { Button, Switch, SwitchLabel } from '@barracuda-internal/bds-core';
import { Avatar, Container, Grid, SvgIconProps } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { ReactElement, useState } from 'react';
import LoadingIndicator from 'src/lib/components/LoadingIndicator';
import { ClassifierView } from 'src/types/dataTypes';
import ulog from 'ulog';

export interface DialogFormState {
	disabled: boolean;
}

export interface EditViewDialogProps {
	baseClassifier: ClassifierView;
	children: React.ReactElement;
	Icon: React.ComponentType<SvgIconProps>;
	isLoading: boolean;
	isSaving: boolean;
	onChange: (DialogFormState) => void;
	onClose?: () => void;
	onSave?: () => void;
	title: string;
}

export function debugCustomClassifier() {
	ulog('Custom Classifier:', ...arguments);
}

const useStyles = makeStyles((theme) => ({
	root: {
		margin: 0,
		'& h3, & h1': {
			margin: 0,
		},
		'& header': {
			marginBottom: theme.spacing(3),
			paddingTop: theme.spacing(2),
		},
		'& aside': {
			width: '300px',
			backgroundColor: 'lightgray',
			padding: theme.spacing(1),
		},
		'& footer button': {
			marginLeft: theme.spacing(2),
		},
	},
	flexAndCenter: {
		justifyContent: 'center',
		alignItems: 'center',
		display: 'flex',
	},
	flexAndVerticalCenter: {
		display: 'flex',
		alignItems: 'center',
	},
	toggle: {
		display: 'flex',
		justifyContent: 'flex-end',
	},
	main: {
		display: 'flex',
		flex: 1,
	},
	rightColumn: {
		flexDirection: 'column',
		display: 'flex',
		flex: 1,
		'& footer': {
			alignItems: 'center',
			display: 'flex',
			height: '50px',
			justifyContent: 'flex-start',
			padding: `${theme.spacing(2)} 0`,
		},
	},
	title: {
		alignItems: 'center',
		display: 'flex',
	},
	wrapper: {
		display: 'flex',
		flex: 1,
	},
}));

function EditViewDialog({
	baseClassifier,
	children,
	Icon,
	isLoading,
	isSaving,
	onClose,
	onChange,
	onSave,
	title,
}: EditViewDialogProps): ReactElement {
	const classes = useStyles();

	const [formState, setFormState] = useState({
		disabled: baseClassifier.disabled,
	});

	if (isLoading) return <LoadingIndicator message="Loading" />;

	const handleDisableChange = (e) => {
		const newState = {
			...formState,
			disabled: !e.target.checked,
		};

		setFormState(newState);
		onChange(newState);
	};

	const handleSubmit = (e) => e.preventDefault();

	return (
		<Container
			component="form"
			onSubmit={handleSubmit}
			className={classes.root}
		>
			<header>
				<Grid container spacing={3}>
					<Grid item xs={1} className={classes.flexAndCenter}>
						<Avatar style={{ backgroundColor: 'black' }}>
							<Icon style={{ color: 'white' }} />
						</Avatar>
					</Grid>
					<Grid item xs={7} className={classes.flexAndVerticalCenter}>
						<h1>{title}</h1>
					</Grid>
					<Grid item xs={4} className={classes.toggle}>
						<SwitchLabel
							label={!formState.disabled ? 'Enabled' : 'Disabled'}
							labelPlacement="start"
							size="small"
							control={
								<Switch
									checked={!formState.disabled}
									onChange={handleDisableChange}
									color='primary'
								/>
							}
						/>
					</Grid>
				</Grid>
			</header>
			<div className={classes.main}>
				<div className={classes.rightColumn}>
					<div className={classes.wrapper}>{children}</div>
					<footer>
						<Button
							isLoading={isSaving}
							disabled={isSaving}
							variant="contained"
							color="primary"
							onClick={onSave}
							type="submit"
						>
							Save
						</Button>
						<Button onClick={onClose} color="primary">
							Close
						</Button>
					</footer>
				</div>
			</div>
		</Container>
	);
}

export default EditViewDialog;
