import * as Sentry from '@sentry/react';
import mixpanel from 'mixpanel-browser';
import React, { ReactElement, useEffect, useState } from 'react';
import ReactGA from 'react-ga';
import { Route, RouteProps, useLocation } from 'react-router-dom';
import Center from 'src/components/Center';
import FullPageError from 'src/components/FullPageError';

import TermsAndConditions from 'src/components/TermsAndConditions';
import FEATURES from 'src/featureMap';
import { useApiRequest } from 'src/hooks/useApi';
import { useAppToolchain } from 'src/hooks/useAppToolchain';
import useAuth from 'src/hooks/useAuth';
import LoadingIndicator from 'src/lib/components/LoadingIndicator';
import BaseLayout from 'src/routes/layouts/BaseLayout';
import ulog from 'ulog';
import { version } from '../../package.json';
import SideNav from 'src/components/SideNav';
import { BDSProvider } from '@barracuda-internal/bds-core';
import { getAppConfig } from 'src/AppLoader';


interface Props extends RouteProps {
	title: string;
	requireAuth?: boolean;
	requireOnboarding?: boolean;
	requireAcceptedTerms?: boolean;
	requirePIConsent?: boolean;
	sidebar?: boolean;
}

/**
 * This component handles:
 * - Anything that needs to run ON EVERY URL CHANGE
 * - App-level error handling via Sentry
 */
export var storageRegion = ""
function AppRoute({
	title,
	requireAuth = false,
	requireOnboarding = false,
	requireAcceptedTerms = true,
	requirePIConsent = true,
	sidebar = true,
	...props
}: Props): ReactElement {
	const { component: Component, ...routerProps } = props;
	const { api } = useAppToolchain();
	const [doTermsAccepted, setDoTermsAccepted] = useState(false);
	const { auth } = useAuth();

	const location = useLocation();

	ulog.debug(`APP: Page changed to ${location.pathname}`);

	useEffect(() => {
		const action = FEATURES.pageChange;
		mixpanel.track(`DIN ${action.id}`, {
			urlPath: location.pathname,
			feature: action.feature,
			appVersion: version,
		});
	}, [location]);

	useEffect(() => {
		if (window?.ga) {
			ReactGA.pageview(`${location.pathname}${location.search}`);
		}
	}, [location]);

	useEffect(() => {
		if (window?.Intercom)
			window.Intercom('update', {
				last_request_at: new Date().getTime() / 1000,
			});
	}, []);

	const [userReqStatus, user, error] = useApiRequest(
		requireAcceptedTerms && ((signal) => api.getLoggedInUser({ signal })),
		[requireAcceptedTerms, doTermsAccepted],
	);
	
	useApiRequest(() => {
        console.log("getAccountDetails")
        if (!auth) return;
        return api
            .getAccountConfig(auth?.accountDetails.account.id)
            .then((res) => {
                //alert("working")
                //console.log("config-",config);
                console.log("storageRegion_appRoute",res.storageRegion)
                storageRegion = (res.storageRegion)
            });
    }, [auth]);
	useEffect(()=>{getAppConfig()})
	let renderer = (props) => {
		if (requireAcceptedTerms) {
			if (userReqStatus !== 'RESOLVED') {
				return (
					<BaseLayout>
						<Center>
							<div>
								{error && error?.status !== 401 ? (
									<FullPageError message="There was an error loading the application." />
								) : (
									<LoadingIndicator
										message="Loading..."
										loaderSize={100}
									/>
								)}
							</div>
						</Center>
					</BaseLayout>
				);
			}

			if (user && !user?.config?.termsAgreeTime) {
				return (
					<TermsAndConditions
						onAccepted={() => setDoTermsAccepted(true)}
					/>
				);
			}
		}

		return (
			<>
				<Component {...props} />
			</>
		);
	};

	// NOTE: Any passed in `render` function will override ours.
	return (
		<Sentry.ErrorBoundary fallback={<p>An error has occurred</p>}>
			<BDSProvider useBdsTheme={true}>
				{sidebar ? (
					<SideNav>
						<Route {...routerProps} render={renderer} />
					</SideNav>
				) : (
					<Route {...routerProps} render={renderer} />
				)}
			</BDSProvider>
		</Sentry.ErrorBoundary>
	);
}

export default AppRoute;
