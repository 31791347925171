import React from 'react';

/**
 * 
 * This button is hidden button used OneTrust Javascript to register attach its popup... We should click event on below button to show Cookie Settings popup
 */
export default function CookieSettingContButton({
}: {
}): React.ReactElement {
    return <button id="ot-sdk-btn" className="ot-sdk-show-settings" style={{ display: 'none' }}>
        Open Cookie Settings
    </button>;
}
