import React from 'react';
import { Skeleton } from '@material-ui/lab';

interface ShimmerProps {
    shimmerWidth: number[];
    skeletonHeight: number;
    animation?: 'pulse' | 'wave' | false;
    style?: React.CSSProperties
}

const Shimmer: React.FC<ShimmerProps> = ({
    shimmerWidth = [],
    skeletonHeight = 0,
    animation = 'wave',
    style = {}
}) => {
  return (
    <>
        {shimmerWidth.map((itemWidth, index) => (
            <Skeleton
                key={index}
                animation={animation}
                style={{ ...style, width: `${itemWidth}%`, height: skeletonHeight }}
            />
        ))}
    </>
  );
};

export default Shimmer;
