import { CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';

const useStyles = makeStyles((theme) => ({
	root: {
		alignItems: 'center',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		padding: theme.spacing(4),
	},
}));

function LoadingIndicator({
	loaderSize = undefined,
	message,
	messageOnly = false,
	...rest
}: {
	loaderSize?: number;
	message?: string;
	messageOnly?: boolean;
}) {
	const classes = useStyles();
	return (
		<div
			className={classes.root}
			aria-live="polite"
			role="status"
			{...rest}
		>
			{messageOnly !== true ? (
				<CircularProgress size={loaderSize} />
			) : null}
			{message ? <p>{message}</p> : null}
		</div>
	);
}

export default LoadingIndicator;
