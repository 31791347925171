interface ValidationProps {
    value: string;
    maxlength?: number;
    minlength?: number;
}

export const validate = ({ value, maxlength=64, minlength=1 }: ValidationProps): string | null => {
    if (value.trim() === '') {
        return `This field is required`;
    }

    if (maxlength && value.length > maxlength) {
        return `This field must be no more than ${maxlength} characters`;
    }

    if (minlength && value.length < minlength) {
        return `This field must be at least ${minlength} characters`;
    }

    return null;
};
