import React from 'react';
import { withRequireOnboarding } from 'src/components/withRequireOnboarding';
import SettingsPage from 'src/routes/SettingsPage';

export default function SettingsRoute() {
	const Page = withRequireOnboarding(SettingsPage);
	return (
			<Page />
	);
}
