import React, { useContext } from 'react';
import { styled } from '@mui/material/styles';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { Typography } from '@material-ui/core';
import { SideDetailContext } from './SideDetailPanel';

const PreviewToolTip = ({finding, previewIndex, setShowFBPopup, previewUrl}) => {

	const {setFeedbackFinding} = useContext(SideDetailContext);
 
    const getFindingStyle = (finding) => {
		const style: React.CSSProperties = {
			position: 'absolute',
			left: `${finding.left}px`,
			top: `${finding.top}px`,
			width: `${finding.width}px`,
			height: `${finding.height}px`,
			cursor: 'pointer',
		  };
		return style;
	}

	const openFeedbackPopUp = async () => {
		setShowFBPopup(true);
		setFeedbackFinding({...finding, previewUrl, previewIndex});		
	}

    return (
        <div onClick={openFeedbackPopUp}>
            <LightTooltip 
                title={
                    <>
                        <Typography style={{fontSize: '12px', lineHeight: 1.5}}><b>{finding.findingTag}</b></Typography>
                        <Typography style={{fontSize: '12px', lineHeight: 1.5}}>Click to provide feedback and remove the detection.</Typography>
                    </>
                }>
                <div  style={getFindingStyle(finding)}></div>
            </LightTooltip>
        </div>
    )
}

const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
	<Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
	[`& .${tooltipClasses.tooltip}`]: {
	  backgroundColor: '#646464',
	  color: '#ffffff',
	  boxShadow: theme.shadows[1],
	  fontSize: '12px',
	},
  }));

export default PreviewToolTip