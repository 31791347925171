import React from 'react';
import TextField from '@material-ui/core/TextField';

type Variant = "small" | "medium";

type DescriptionProps = {
  limit: number;
  labelTxt: string;
  variant: Variant;
  value: string;
  setValue: (value: string) => void;
};

function DescriptionInput({limit, labelTxt, variant, value, setValue}: DescriptionProps) {

  const handleChange = (event) => {
    if (event.target.value.length <= limit) {
      setValue(event.target.value);
    }
  };
  const characterCount = value.length;

  return (
    <div style={{display: 'flex', margin: '10px 0px'}}>
    <TextField
      label={labelTxt}
      multiline
      variant="outlined"
      value={value}
      onChange={handleChange}
      size={variant}
      inputProps={{ maxLength: limit }}
      style={{width: '40%'}}
    />
    <p style={{marginTop: 'auto', marginBottom: '0px', fontSize: '10px', color: 'gray', marginLeft: '5px'}}>
        {characterCount} / {limit}
    </p>
    </div>

  );
}

export default DescriptionInput;