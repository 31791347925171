import { withStyles } from '@material-ui/core/styles';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import React from 'react';


const styles = {
	errorBlock: {
		height: '100%',
		width: '100%',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
};

interface CodeusErrorProps {
	children: React.ReactElement;
	classes?: Partial<ClassNameMap<keyof typeof styles>>;
	errorComponent?: React.ReactElement;
}

interface CodeusErrorState {
	hasError: boolean;
}

/**
 * Creates a React error boundary wrapped up with
 * expected Codeus design.
 */
class CodeusErrorBoundary extends React.Component<
	CodeusErrorProps,
	CodeusErrorState
> {
	constructor(props: CodeusErrorProps) {
		super(props);
		this.state = { hasError: false };
	}

	static getDerivedStateFromError(e): CodeusErrorState {
		console.log(e);
		return { hasError: true };
	}

	render(): React.ReactElement {
		if (this.state.hasError) {
			if (this.props.errorComponent) return this.props.errorComponent;

			return (
				<div className={this.props.classes.errorBlock}>
					<div style={{ textAlign: 'center', padding: '16px' }}>
						<h1>Something went wrong.</h1>
						<p>An unexpected error occured.</p>
					</div>
				</div>
			);
		}

		return this.props.children;
	}
}

export default withStyles(styles)(CodeusErrorBoundary);
